import BasePatternDataService from "./base-pattern-data-service";
import colors from "styles/colors";
const color_regex = ".+?";
// "This is an <monday-color blue>Example</monday-color> for a <monday-color pink>colorful title</monday-color>."
const INNER_REGEX_EXPRESSION = `(<monday-color (${color_regex})>(.*?)</monday-color>)`;
const REGEX_EXPRESSION = `(<monday-color .+?>${color_regex}</monday-color>)`;

export default class ColorPatternDataService extends BasePatternDataService {
  regex() {
    return REGEX_EXPRESSION;
  }

  isRecursive() {
    return false;
  }

  patternCharsCount() {
    return 0;
  }

  // <monday-color blue>Example</monday-color>
  replacer(value) {
    const result = value.match(INNER_REGEX_EXPRESSION);
    const color = result[2];
    const text = result[3];
    return `<span class="colored" style="color: ${colors[color]}; font: inherit;">${text}</span>`;
  }
}
