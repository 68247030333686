import RightArrow from "static/svg/arrow-right-3.svg";
import { componentStyles } from "./button-arrow.scss";
import classnames from "classnames";
import { MD } from "constants/sizes";

const ButtonArrow = (props) => {
  const { size, hover, isSignup } = props;

  return (
    <span className={classnames("arrow-wrapper", size, { hover: hover, signup: isSignup })}>
      <RightArrow />
      <style jsx>{componentStyles}</style>
    </span>
  );
};

ButtonArrow.defaultProps = {
  size: MD
}

export default ButtonArrow;
