import { useState, useRef, useEffect } from "react";
import { MOBILE_QUERY } from "/constants/vp-sizes";
import {
  generateCloudinaryImageUrl,
  extractCloudinaryTransformations,
  isCloudinaryUrl,
  LOW_RES_IMAGE_QUALITY_LEVEL,
  IMAGE_DEFAULT_BLUR
} from "/services/community/cloudinary-service";
import { isImageLoaded } from "services/dom-utils-service";
import classnames from "classnames";
import VisibilitySensor from "react-visibility-sensor";
import { withGenericConfig } from "/contexts/generic-config-context";
import { lazyLoad, removeLazyLoadProperty } from "client/services/images-lazy-loading/images-lazy-loading";
import { PICTURE_LAZY_CLASS, PICTURE_LAZY_ATTRIBUTE } from "constants/lazy-load-images";
import { MOUSE_ENTER_IMAGE_ELEMENT, CLICK_IMAGE_ELEMENT } from "constants/bigbrain-event-types";
import { removeFileExtension } from "server/services/files-service/files-service";
import { isUUID, UUID_LENGTH } from "services/utils-service";
import { componentStyles } from "./picture-component.scss";

const PictureComponent = (props) => {
  const [useLazyLoad, setUseLazyLoad] = useState(false);
  const _image = useRef(null);
  const {
    shouldUseLazyLoad,
    mobile,
    imagesLazyLoad,
    src,
    alt,
    shouldTrackMouseEvents,
    className,
    pictureClass,
    specificImageStyle,
    assetQuality,
    isDecorative,
    extraCloudinaryTransformations,
    isPreview,
    skipCloudinaryConversion,
    pictureComponentHeight
  } = props;

  useEffect(() => {
    lazyLoadImageIfNeeded();

    // This is to make sure picturefill is loaded only on the client as it breaks when loaded on the server
    const picturefill = require("picturefill");
    picturefill();
  }, []);

  useEffect(() => {
    lazyLoadImageIfNeeded();
  }, [src]);

  const lazyLoadImageIfNeeded = () => {
    const { current } = _image;

    if (validateLazyLoadImages()) lazyLoad(current);
    else removeLazyLoadProperty(current);
  };

  const isImageCompleted = (img) => {
    return isImageLoaded(_image.current);
  };

  const renderSource = (src, media) => {
    return <source srcSet={src} media={media} />;
  };

  const addLowResTransformations = (cloudinaryTransformations) => {
    return {
      ...cloudinaryTransformations,
      quality: LOW_RES_IMAGE_QUALITY_LEVEL,
      blur: IMAGE_DEFAULT_BLUR
    };
  };

  const addFakeRandomDefaultImageKeyForBrowserCacheBusting = (cloudinaryTransformations) => {
    const randInt = Math.ceil(Math.random() * 1000);

    return {
      ...cloudinaryTransformations,
      defaultImage: randInt
    };
  };

  const getCloudinaryTransformations = () => {
    let cloudinaryTransformations = extractCloudinaryTransformations({
      ...specificImageStyle,
      ...extraCloudinaryTransformations
    });
    if (isLowResImage()) {
      cloudinaryTransformations = addLowResTransformations(cloudinaryTransformations);
    }
    if (isPreview) {
      // for image preview we add a non existing default image key just to clear browser cache for uploading image with same name problem
      cloudinaryTransformations = addFakeRandomDefaultImageKeyForBrowserCacheBusting(cloudinaryTransformations);
    }

    return cloudinaryTransformations;
  };

  const getMobileImageSource = (cloudinaryTransformations) => {
    if (!mobile) return null;

    if (isCloudinaryUrl(mobile) || skipCloudinaryConversion) return mobile;

    return generateCloudinaryImageUrl(mobile, cloudinaryTransformations);
  };

  const getImageNameFromSrc = (imgSrc) => {
    if (!imgSrc) return "";
    try {
      const lastSeparator = imgSrc.lastIndexOf("/");
      const fileName = imgSrc.substr(lastSeparator + 1);
      let fileNameWithoutExtension = removeFileExtension(fileName);

      // our S3 image uploads code adds UUID to files - if this is the case we remove it
      if (fileName.length > UUID_LENGTH && isUUID(fileName.substring(0, UUID_LENGTH))) {
        fileNameWithoutExtension = fileNameWithoutExtension.substring(UUID_LENGTH);
      }

      return fileNameWithoutExtension.replace(/-|_/g, " ").trim(); // replace all - and _ with a space
    } catch (error) {
      console.error(`Error generating alt text for imgSrc: ${imgSrc}:`, error);
      return imgSrc;
    }
  };

  const onVisibilityChange = (visible, data) => {
    if (visible && useLazyLoad) {
      setUseLazyLoad(false);
    }
  };

  // Rendering low res image if needed when using lazy load
  const isLowResImage = () => {
    return useLazyLoad;
  };

  const validateLazyLoadImages = () => {
    if (!window) return false;
    if (!("IntersectionObserver" in window)) return false;

    return shouldUseLazyLoad;
  };

  const onMouseEnter = () => {
    if (!shouldTrackMouseEvents) return;
    BigBrain("track", MOUSE_ENTER_IMAGE_ELEMENT, { placement: src, kind: alt });
  };

  const onClick = () => {
    if (!shouldTrackMouseEvents) return;
    BigBrain("track", CLICK_IMAGE_ELEMENT, { placement: src, kind: alt });
  };

  const renderPicture = () => {
    const cloudinaryTransformations = getCloudinaryTransformations();

    const cloudinaryUrl =
      isCloudinaryUrl(src) || skipCloudinaryConversion
        ? src
        : generateCloudinaryImageUrl(src, cloudinaryTransformations, { assetQuality });
    const mobileSrc = getMobileImageSource(cloudinaryTransformations);
    const imageAlt = isDecorative ? "" : alt || getImageNameFromSrc(src);
    const srcAttr = shouldUseLazyLoad ? PICTURE_LAZY_ATTRIBUTE : "src";

    const imgAttrs = {
      alt: imageAlt,
      ref: _image,
      className: classnames(className, { [PICTURE_LAZY_CLASS]: shouldUseLazyLoad }),
      [srcAttr]: cloudinaryUrl,
      style: specificImageStyle
    };

    const topicImageInlineStyle = pictureComponentHeight
      ? { display: "flex", height: pictureComponentHeight, width: "100%" }
      : {};

    return (
      <>
        <picture
          className={classnames("picture-component", pictureClass, {
            "blur-image": isLowResImage() && !isImageCompleted()
          })}
          style={topicImageInlineStyle}
        >
          {mobileSrc && renderSource(mobileSrc, MOBILE_QUERY)}
          <img {...imgAttrs} onMouseEnter={onMouseEnter} onClick={onClick} />
        </picture>
        <style jsx>{componentStyles}</style>
      </>
    );
  };

  if (imagesLazyLoad) {
    // when using lazy load - images will remain low res until visible in viewport
    return (
      <VisibilitySensor
        offset={{ top: 200 }}
        partialVisibility={true}
        onChange={onVisibilityChange}
        active={true}
        scrollCheck={true}
      >
        {renderPicture()}
      </VisibilitySensor>
    );
  }
  return renderPicture();
};

export default withGenericConfig(PictureComponent);

PictureComponent.defaultProps = {
  className: "",
  src: "",
  mobile: null,
  alt: "",
  pictureClass: "",
  specificImageStyle: {},
  screenRatios: {},
  imagesLazyLoad: false,
  shouldUseLazyLoad: false,
  shouldTrackMouseEvents: false,
  isDecorative: false,
  skipCloudinaryConversion: false
};
