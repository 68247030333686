import { PureComponent } from "react";
import RegularSignupComponent from "components/core/signups/regular-signup-component/regular-signup-component";
import ExpandingSignupComponent from "components/core/signups/expanding-signup-component/expanding-signup-component";
import { withGenericConfig } from "/contexts/generic-config-context";

class SignupFormComponent extends PureComponent {
  render() {
    const { inputPlaceholder, useExpandingSignup } = this.props;

    const SignupComponent = useExpandingSignup ? ExpandingSignupComponent : RegularSignupComponent;

    return <SignupComponent {...this.props} placeholder={inputPlaceholder} />;
  }
}

SignupFormComponent.defaultProps = {
  forceHorizontal: false
};

export default withGenericConfig(SignupFormComponent);
