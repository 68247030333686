import { createContext, forwardRef, PureComponent } from "react";

export const SegmentContext = createContext(null);

export const withSegmentsData = function(WrappedComponent) {
  class SegmentDataComponent extends PureComponent {
    render() {
      const { forwardedRef, ...rest } = this.props;

      return (
        <SegmentContext.Consumer>
          {value => <WrappedComponent ref={forwardedRef} {...rest} {...value} />}
        </SegmentContext.Consumer>
      );
    }
  }

  return forwardRef((props, ref) => {
    return <SegmentDataComponent {...props} forwardedRef={ref} />;
  });
};
