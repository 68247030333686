import { PureComponent } from "react";
import { tooltipStyles } from "./tooltip-component.scss";
import classnames from "classnames";
import { LIGHT_THEME } from "./tooltip-constants";

export default class TooltipComponent extends PureComponent {
  render() {
    const { theme, className } = this.props;
    return (
      <div className={classnames("tooltip", theme, className)}>
        {this.props.children}
        <style jsx>{tooltipStyles}</style>
      </div>
    );
  }
}

TooltipComponent.defaultProps = {
  theme: LIGHT_THEME
};
