import { addParamToURL } from "/utils/url";

const SIGNUP_SOURCE_PARAM = "source";
const WEBSITE_MAIN_SOURCE_VALUE = "web_main";

//	can test locally with overriding abTests with:
//	[{name:softSignupTests[0].test_name, variant: softSignupTests[0].new_variation_name}]

export const addParamsToUrl = (url) => {
  return addParamToURL(url, SIGNUP_SOURCE_PARAM, WEBSITE_MAIN_SOURCE_VALUE);
};
