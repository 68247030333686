export const XXS = "xss";
export const XS_M = "xs_m";
export const XS_L = "xs_l";
export const XS = "xs";
export const SM = "sm";
export const MD = "md";
export const LG = "lg";
export const LGR = "lgr";
export const XL = "xl";
export const XXL = "xxl";
