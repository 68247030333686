import BasePatternDataService from "./base-pattern-data-service";

const REGEX_EXPRESSION = "(<monday-new-line />)";

export default class NewLinePatternDataService extends BasePatternDataService {
  regex() {
    return REGEX_EXPRESSION;
  }

  isRecursive() {
    return false;
  }

  patternCharsCount() {
    return 0;
  }

  replacer(value) {
    return `<br/>`;
  }
}
