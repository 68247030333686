import { PureComponent } from "react";
import classnames from "classnames";
import {
  VERIFY_EMAIL_LINK,
  NEW_SOFT_SIGNUP_LINK,
  SIGNUP_WITH_GOOGLE_LINK,
  getPlatformAddSolutionLink,
  SIGNUP_WITH_PRODUCT_PAGE_LINK,
  PRODUCT_ID_PARAM_NAME
} from "/constants/links";
import { IS_IN_KEEP_REGION_TEST, WEBSITE_PRODUCT_SIGNUP_COOKIE_NAME } from "constants/cookies";
import {
  getClusterIdsFromLastTagsSelection,
  getCurrentClusters,
  getProductByClustersIds,
  updateUserClustersListWithSingleCluster
} from "services/clusters-service";
import {
  getWelcomeBackCTAProps,
  isExistingUserInWelcomeBackTest
} from "services/monday-platform/existing-accounts-service";
import { isInTest } from "services/new-ab-tests/new-ab-tests-service";
import {
  SIGNUP_WITH_PRODUCT_PAGE_WITHOUT_PRODUCT_RECOMMENDATION_TEST,
  SIGNUP_WITH_PRODUCT_PAGE_WITH_PRODUCT_RECOMMENDATION_TEST
} from "constants/new-ab-tests";
import { HP_SIGNUP_BUTTON, SET_CLUSTER_FROM_SIGNUP_BUTTON_REASON } from "constants/clusters/sources";
import Colors from "styles/colors";
import {
  SPACE_BLUE_COLOR_NAME,
  WHITE_COLOR_NAME,
  BLUE_COLOR_NAME,
  WORK_OS_IRIS_COLOR_NAME,
  DODGER_BLUE_COLOR_NAME,
  GRADIANT_WORKOS_IRIS_COLOR_NAME,
  BRAND_BLACK_TEXT_COLOR,
  WORKFORMS_RED_COLOR_NAME,
  DARK_PROJECTS_ORANGE_COLOR_NAME,
  DARK_MARKETING_RED_COLOR_NAME,
  DARK_CRM_GREEN_COLOR_NAME,
  DARK_DEV_GREEN_COLOR_NAME,
  CRM_DARK_TINT_01_COLOR_NAME,
  BRAND_GREEN_DARK_TINT_01_COLOR_NAME,
  BRAND_BLACK_BACKGROUND_COLOR
} from "styles/color-consts";
import { ConditionalWrapper } from "utils/react";
import { getProductSignupURL, getPlatformProductID, shouldGetProductSignup } from "constants/products";
import Button from "/components/core/button/button-component";
import EmailInput from "/components/core/inputs/email-input-component";

import {
  SIGNUP_FORM_SUBMIT,
  CLICKED_SIGNUP_WITHOUT_INSERTING_EMAIL,
  SIGNUP_MAIN_CTA_CLICKED,
  SIGNUP_WITH_GOOGLE_CLICKED,
  SIGNUP_START_FILL_EMAIL,
  SIGNUP_SUBMIT_CLICK_WITH_EMAIL_ERROR,
  PRODUCT_RECOMMENDED_BY_TAGS_SELECTION,
  EXISTING_ACCOUNT_MAIN_CTA_CLICKED
} from "/constants/bigbrain-event-types";
import { withGenericConfig } from "/contexts/generic-config-context";
import { withSegmentsData } from "contexts/segment-context";
import { withAbTests } from "contexts/ab-tests-context";
import { addParamToURL } from "utils/url";
import { translateToEnglish } from "services/localization/localization-service";
import {
  shouldRenderSignupPopupAbTest,
  shouldGetKeepRegionTest,
  shouldRenderSignupPopupWithoutAssetAbTest,
  shouldSendToSignupWithProductPageWithoutProductRecommendationTest,
  shouldSendToSignupWithProductPageWithProductRecommendationTest,
  shouldGetUnlimitedTimeCTATextTest
} from "services/ab-tests/acquisition-ab-test-service";
import { trackEvent } from "/services/bigbrain-service";

import { PREVENT_FREE_TIER_PARAM } from "constants/query-params";
import { getScrollPercent } from "/services/dom-utils-service";
import { setCookieIfNeeded, setCookie } from "services/cookies-service";
import { BELOW } from "/constants/button_additional_text_position";
import MondayMarkupLanguageComponent from "client/components/system/monday-markup-language/monday-markup-language-component";
import { CLUSTER_TYPE } from "/constants/clusters/types";
import { GENERIC_CLUSTER } from "constants/clusters";
import DataLayerEventTypes from "constants/data-layer-event-types";
import { fireGtmEvent } from "client/services/gtm-service/gtm-service";
import GoogleIconSvg from "static/svg/google-icon.svg";

import { appendUrlSchemeIfNeeded, isAndroid, isMeta } from "utils/mobile";
import { componentStyles } from "./signup-form-core-component.scss";
import { addParamsToUrl } from "./signup-form-core-service";

const DEFAULT_CTA_KEY = "signup.cta";
const SIGNUP = "sign-up";
const SIGNUP_POPUP = "sign-up-popup";

const BRAND_ALLOWED_COLORS = [
  WHITE_COLOR_NAME,
  BLUE_COLOR_NAME,
  WORK_OS_IRIS_COLOR_NAME,
  SPACE_BLUE_COLOR_NAME,
  GRADIANT_WORKOS_IRIS_COLOR_NAME,
  BRAND_BLACK_TEXT_COLOR,
  BRAND_BLACK_BACKGROUND_COLOR,
  WORKFORMS_RED_COLOR_NAME,
  DARK_MARKETING_RED_COLOR_NAME,
  DARK_CRM_GREEN_COLOR_NAME,
  DARK_PROJECTS_ORANGE_COLOR_NAME,
  DARK_DEV_GREEN_COLOR_NAME,
  CRM_DARK_TINT_01_COLOR_NAME,
  BRAND_GREEN_DARK_TINT_01_COLOR_NAME
];

const loadedAt = new Date().getTime();

class SignupCoreComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { emailValue: "", isLoading: false, isGoogleConnectLoading: false };

    this._emailInput = null;
    this.updateEmail = this.updateEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserSignup = this.handleUserSignup.bind(this);
    this.submitFormIfNeeded = this.submitFormIfNeeded.bind(this);
    this.onClickSignup = this.onClick.bind(this, SIGNUP);
    this.onlyButton = this.shouldSeeOnlyButton(props);

    const { isMobileOrTabletBrowser, additionalTextPosition } = props;
    this.additionalTextPosition = isMobileOrTabletBrowser ? BELOW : additionalTextPosition;
  }

  shouldSeeOnlyButton(props) {
    const { forceInputHide, forceDefaultForm, isMobileOrTabletBrowser } = props;

    if (forceInputHide) return true;
    if (forceDefaultForm) return false;
    if (isMobileOrTabletBrowser) return false;

    return true;
  }

  getSignupProductId = () => {
    const { signupProductId, pageProductId } = this.props;
    return signupProductId || pageProductId;
  };

  getSignupClusterId = () => {
    const { signupClusterId, pageClusterId } = this.props;
    return signupClusterId || pageClusterId;
  };

  setKeepRegionTestCookieIfNeeded = () => {
    const { abTests, cloudfrontViewerCountry, isMobileOrTabletBrowser, signupWithsolutionAppReferenceId } = this.props;
    if (!this.getSignupProductId() && !isMobileOrTabletBrowser && !signupWithsolutionAppReferenceId) {
      // we exclude mobile viewers from the test at the moment
      // we also exclude the products from it at the moment since we're not sure they're available in AU
      if (shouldGetKeepRegionTest(abTests, cloudfrontViewerCountry, true)) {
        // we want to run the test only for regular desktop signups
        setCookieIfNeeded(IS_IN_KEEP_REGION_TEST, true);
      }
    }
  };

  getSubmitUrl() {
    const { submitUrl, signupWithsolutionAppReferenceId, forceProductSignup } = this.props;
    if (!this.onlyButton) return addParamsToUrl(VERIFY_EMAIL_LINK); // mobile / popup - with email
    if (signupWithsolutionAppReferenceId) return getPlatformAddSolutionLink(signupWithsolutionAppReferenceId); // sign up with specific solution - For now using this just for desktop

    let signupUrl = submitUrl;
    const productIdToUse = this.getSignupProductId();
    if (productIdToUse && (forceProductSignup || shouldGetProductSignup(productIdToUse))) {
      signupUrl = getProductSignupURL(productIdToUse);
    }

    const url = addParamsToUrl(signupUrl);
    return url;
  }

  componentDidMount() {
    const { focusOnLoad, isFirstSegment, isPreview } = this.props;
    if (isPreview) return;

    const shouldFocus = focusOnLoad || isFirstSegment;
    shouldFocus && this.focusOnInput();

    window.addEventListener("pageshow", this.subscribeToBackNavigation);
  }

  componentWillUnmount() {
    window.removeEventListener("pageshow", this.subscribeToBackNavigation);
  }

  subscribeToBackNavigation = (event) => {
    if (event?.persisted) {
      this.setState({ isLoading: false, isGoogleConnectLoading: false });
    }
  };

  focusOnInput = () => {
    this._emailInput && this._emailInput.focus();
  };

  submitFormIfNeeded(event) {
    if (event.key === "Enter") {
      this.handleSubmit();
      event.preventDefault();
    }
  }

  updateEmail(event) {
    const { emailValue } = this.state;
    const { errorStateCallback, parentSegment, segmentNumber } = this.props;

    if (!emailValue)
      trackEvent(SIGNUP_START_FILL_EMAIL, {
        info1: parentSegment,
        info2: this.getButtonText(),
        info3: segmentNumber
      });

    this.setState({ emailValue: event.target.value });
    errorStateCallback(false);
  }

  handleBeforeSubmitCallback = () => {
    const { beforeSubmitCallback, shouldSendClustersAsParams } = this.props;

    if (beforeSubmitCallback) {
      const response = beforeSubmitCallback();

      // Add clusters to form action url
      // We run this here because clusterIds are manipulated after click
      // When the submitUrl is already assigned to the form
      if (response && response.params && shouldSendClustersAsParams) {
        const keys = Object.keys(response.params);

        keys.forEach((key) => {
          this._form.action = addParamToURL(this._form.action, key, response.params[key]);
        });
      }
    }
  };

  async handleSubmit(kind = SIGNUP) {
    const { errorStateCallback, placement, parentSegment, buttonSource, segmentNumber } = this.props;

    const loadingButtonKey = "isLoading";
    this.setState({ [loadingButtonKey]: true });

    if (!this.onlyButton && !this._emailInput.isValid()) {
      const { emailValue } = this.state;

      this.setState({ isLoading: false });
      this._emailInput.focus();
      errorStateCallback(true);
      trackEvent(SIGNUP_SUBMIT_CLICK_WITH_EMAIL_ERROR, {
        info1: emailValue,
        info2: parentSegment,
        info3: segmentNumber
      }); // Not PII since email isn't valid.
      return;
    }
    trackEvent(SIGNUP_FORM_SUBMIT, { placement, kind, info1: parentSegment, info3: buttonSource });

    this.handleBeforeSubmitCallback();

    await this.handleUserSignup(kind);
  }

  updateUserCluster = () => {
    const { pageSubClusterId } = this.props;

    const clusterToReport = this.getSignupClusterId() || GENERIC_CLUSTER;
    updateUserClustersListWithSingleCluster(clusterToReport, CLUSTER_TYPE, HP_SIGNUP_BUTTON, {
      setSignupCluster: true,
      reason: SET_CLUSTER_FROM_SIGNUP_BUTTON_REASON,
      sendBigBrainEvent: true,
      useCaseId: pageSubClusterId
    });
  };

  isEligibleForSignupWithProductPageTest = () => {
    const { abTests, sendToSignupWithProductPage, signupWithsolutionAppReferenceId, isLocatedInPopup } = this.props;

    // if signup is defined with a solution id or a product id, don't enter the test
    if (this.getSignupProductId() || signupWithsolutionAppReferenceId || isLocatedInPopup) {
      return false;
    }

    // if page prop is set to true don't enter the test and send to signup with product screen
    if (sendToSignupWithProductPage) {
      return true;
    }

    return (
      isInTest(SIGNUP_WITH_PRODUCT_PAGE_WITHOUT_PRODUCT_RECOMMENDATION_TEST, abTests) ||
      isInTest(SIGNUP_WITH_PRODUCT_PAGE_WITH_PRODUCT_RECOMMENDATION_TEST, abTests)
    );
  };

  shouldSendToSignupWithProductPage = (productId) => {
    const { abTests, sendToSignupWithProductPage, signupWithsolutionAppReferenceId, isLocatedInPopup } = this.props;

    // if signup is defined with a solution id or a product id, don't enter the test
    if (this.getSignupProductId() || signupWithsolutionAppReferenceId || isLocatedInPopup) {
      return false;
    }

    // if page prop is set to true don't enter the test and send to signup with product screen
    if (sendToSignupWithProductPage) {
      return true;
    }

    if (productId) {
      return shouldSendToSignupWithProductPageWithProductRecommendationTest(abTests, true);
    }
    return shouldSendToSignupWithProductPageWithoutProductRecommendationTest(abTests, true);
  };

  async handleUserSignup(kind) {
    const { skipUpdateCluster } = this.props;

    if (!skipUpdateCluster) {
      this.updateUserCluster();
    }
    const scrollPercent = await getScrollPercent();

    this.sendGetStartedEvent(kind, scrollPercent);

    if (this.isEligibleForSignupWithProductPageTest()) {
      const clusters = getCurrentClusters();
      const clustersIds = getClusterIdsFromLastTagsSelection(clusters);
      const productId = getProductByClustersIds(clustersIds);

      if (this.shouldSendToSignupWithProductPage(productId)) {
        trackEvent(PRODUCT_RECOMMENDED_BY_TAGS_SELECTION, {
          kind: productId,
          info1: clustersIds
        });

        let signupWithProductPageLink = SIGNUP_WITH_PRODUCT_PAGE_LINK;
        if (productId) {
          signupWithProductPageLink = addParamToURL(SIGNUP_WITH_PRODUCT_PAGE_LINK, PRODUCT_ID_PARAM_NAME, productId);
        }
        window.location = signupWithProductPageLink;
        return;
      }
    }
    this._form.submit();
  }

  sendGetStartedEvent = (kind, scrollPercent) => {
    const { placement, buttonId, signupClusterId, segmentNumber, segmentType, extraData, useCtaText, ctaText } =
      this.props;

    let englishText;
    if (!useCtaText) {
      englishText = translateToEnglish(DEFAULT_CTA_KEY);
    }

    const ctaClickData = {
      placement,
      kind,
      info1: segmentType,
      info2: this.getButtonText(),
      info3: signupClusterId,
      direct_object_id: scrollPercent,
      direct_object: window.visitGuid,
      board_kind: buttonId,
      value: segmentNumber,
      data: { timeStillPageLoad: new Date().getTime() - loadedAt, englishText, ...extraData }
    };

    trackEvent(SIGNUP_MAIN_CTA_CLICKED, ctaClickData);
    fireGtmEvent(DataLayerEventTypes.SIGNUP_CLICKED);
  };

  shouldReplaceGetStartedWithGoToAccount = () => {
    const {
      abTests,
      useCtaText,
      signupWithsolutionAppReferenceId,
      isLocatedInPopup,
      forceGoToMyAccount,
      persistantCookies,
      pricingDetails
    } = this.props;
    if (forceGoToMyAccount) return true;
    if ((useCtaText && !pricingDetails) || signupWithsolutionAppReferenceId || isLocatedInPopup) return false;

    return this.onlyButton && isExistingUserInWelcomeBackTest(abTests, persistantCookies);
  };

  getButtonText() {
    const { useCtaText, ctaText, translate } = this.props;
    // From props
    if (useCtaText && ctaText) return ctaText;

    return translate(DEFAULT_CTA_KEY);
  }

  getPlaceholderText() {
    const { placeholder, translate } = this.props;
    return placeholder || translate("signup.emailInputPlaceholder");
  }

  onClick(kind) {
    const { emailValue, isLoading } = this.state;

    if (isLoading) return; //	disable in button cause css issues, need to think about it

    if (!this.onlyButton && !emailValue) {
      trackEvent(CLICKED_SIGNUP_WITHOUT_INSERTING_EMAIL);
    }

    this.handleSubmit(kind);
  }

  renderTextNextToButtonIfNeeded = () => {
    const { additionalText, additionalTextMaxWidth, abTests, translate } = this.props;
    if (!additionalText) return;

    let textToUse = additionalText;
    let maxWidthToUse = additionalTextMaxWidth;
    if (textToUse.indexOf("Free forever. No credit card.") > -1 && shouldGetUnlimitedTimeCTATextTest(abTests, true)) {
      textToUse = translate("signup.button.unlimitedTimeCta");
      maxWidthToUse = null;
    }

    return (
      <div
        className={classnames("additional-button-text", this.additionalTextPosition)}
        style={{ maxWidth: maxWidthToUse }}
      >
        <MondayMarkupLanguageComponent text={textToUse} />
      </div>
    );
  };

  clickGoogleConnectAction = () => {
    const { skipUpdateCluster } = this.props;
    this.setState({ isGoogleConnectLoading: true });
    trackEvent(SIGNUP_WITH_GOOGLE_CLICKED);
    fireGtmEvent(DataLayerEventTypes.SIGNUP_WITH_GOOGLE_CLICKED);
    this.handleBeforeSubmitCallback();
    if (!skipUpdateCluster) {
      this.updateUserCluster();
    }
    this.setKeepRegionTestCookieIfNeeded();
    window.location.href = appendUrlSchemeIfNeeded(SIGNUP_WITH_GOOGLE_LINK);
  };

  shouldRenderMobileOrTabletGoogleConnect = () => {
    const { isMobileOrTabletBrowser, showGoogleConnectOnMobile, forceVerticalGoogleConnect } = this.props;

    return (
      (isMobileOrTabletBrowser && showGoogleConnectOnMobile && (!isMeta() || (isMeta() && isAndroid()))) ||
      forceVerticalGoogleConnect
    );
  };

  shouldRenderDesktopGoogleConnect = () => {
    const { isMobileOrTabletBrowser, showGoogleConnectOnDesktop } = this.props;
    return !isMobileOrTabletBrowser && showGoogleConnectOnDesktop;
  };

  renderGoogleConnect = () => {
    if (this.shouldRenderMobileOrTabletGoogleConnect()) return this.renderMobileGoogleConnect();

    if (this.shouldRenderDesktopGoogleConnect()) return this.renderDesktopGoogleConnect();

    return null;
  };

  renderDesktopGoogleConnect = () => {
    return <div className="google-connect-button-wrapper">{this.googleConnectButton()}</div>;
  };

  googleConnectButton = () => {
    const { translate, buttonSize, googleOverrideCtaKey } = this.props;
    const { isGoogleConnectLoading } = this.state;

    const googleConnectColorData = this.getGoogleConnectColorData();
    const googleConnectKey = googleOverrideCtaKey || "signup.continueWith";

    return (
      <Button
        className="google-connect-button"
        color={googleConnectColorData.buttonColor}
        size={buttonSize}
        onClickCallback={this.clickGoogleConnectAction}
        isLoading={isGoogleConnectLoading}
        dataSpinnerColor={Colors[googleConnectColorData.spinnerColorName]}
        withBorder="true"
      >
        <div className="google-button-inner">
          <span className="continue-text"> {translate(googleConnectKey)} </span>
          <GoogleIconSvg className="google-icon" />
        </div>
      </Button>
    );
  };

  renderMobileGoogleConnect = () => {
    const { translate } = this.props;

    return (
      <div className="social-connect-section">
        <div className="google-separator">
          <span className="seperator-line" />
          <span className="separator-text">{translate("signup.or")} </span>
          <span className="seperator-line" />
        </div>
        {this.googleConnectButton()}
      </div>
    );
  };

  getGoogleConnectColorData = () => {
    const buttonColor = this.getButtonColor();
    const options = {
      [WORK_OS_IRIS_COLOR_NAME]: { spinnerColorName: WORK_OS_IRIS_COLOR_NAME, buttonColor: "white-iris" },
      [DODGER_BLUE_COLOR_NAME]: { spinnerColorName: DODGER_BLUE_COLOR_NAME, buttonColor: "white-dodger" },
      [SPACE_BLUE_COLOR_NAME]: { spinnerColorName: SPACE_BLUE_COLOR_NAME, buttonColor: "white" }
    };

    return options[buttonColor] || { spinnerColorName: SPACE_BLUE_COLOR_NAME, buttonColor: "white" };
  };

  getButtonColor = () => {
    const { buttonColor } = this.props;
    if (!BRAND_ALLOWED_COLORS.includes(buttonColor)) {
      return WORK_OS_IRIS_COLOR_NAME;
    }
    return buttonColor;
  };

  signupClicked = () => {
    const {
      useSignUpPopUp,
      abTests,
      openSignupPopup,
      onButtonClick,
      isMobileOrTabletBrowser,
      isLocatedInPopup,
      beforeSubmitCallback,
      signupWithsolutionAppReferenceId
    } = this.props;

    const productForSignup = this.getSignupProductId();
    if (productForSignup && this.onlyButton) {
      // fire the test event - product signup currently requires soft signup in the platform
      setCookie(WEBSITE_PRODUCT_SIGNUP_COOKIE_NAME, getPlatformProductID(productForSignup), { expires: 1 }); // Mark product cookie to sync with platform - live for 1 day
    } else {
      // All special signup behaviors not relevant for Product signups

      // default behavior is overriden by the onButtonClick function (e.g. header popup)
      if (onButtonClick) {
        onButtonClick();
        this.sendGetStartedEvent(SIGNUP_POPUP); // only override is signup popup
        return;
      }

      // SIGNUP modal popups:
      // default signup button behavior; popup shouldn't appear in mobile or in signup with solution
      if (openSignupPopup && !isMobileOrTabletBrowser && !isLocatedInPopup && !signupWithsolutionAppReferenceId) {
        if (
          useSignUpPopUp ||
          shouldRenderSignupPopupAbTest(abTests, true) ||
          shouldRenderSignupPopupWithoutAssetAbTest(abTests, true)
        ) {
          openSignupPopup({ beforeSubmitCallback });
          this.sendGetStartedEvent(SIGNUP_POPUP);
          return;
        }
      }
    }

    this.setKeepRegionTestCookieIfNeeded();
    this.onClickSignup();
  };

  gotToAccount = (platformRedirectUrl, ctaTextKey) => {
    const { buttonId, signupClusterId, segmentNumber, segmentType, translate } = this.props;

    const buttonTextToUse = translate(ctaTextKey);
    const englishText = translateToEnglish(ctaTextKey);

    const ctaClickData = {
      placement: this.getSignupProductId(),
      kind: platformRedirectUrl,
      info1: segmentType,
      info2: buttonTextToUse,
      info3: signupClusterId,
      board_kind: buttonId,
      value: segmentNumber,
      data: { englishText }
    };
    trackEvent(EXISTING_ACCOUNT_MAIN_CTA_CLICKED, ctaClickData);
    window.location = platformRedirectUrl;
  };

  render() {
    const {
      buttonSize,
      origin,
      isErrorState,
      className,
      forceHorizontal,
      emailInputOpened,
      borderColor,
      disableButtonAnimation,
      placeholderFocusColor,
      withButtonArrow,
      buttonSpinnerColor,
      freeTierForcePrevent,
      additionalText,
      isMobileInputTransparent,
      isMobileOrTabletBrowser,
      showGoogleConnectOnMobile,
      invert,
      displayedOnDarkTheme,
      showGoogleConnectOnDesktop,
      forceVerticalGoogleConnect,
      translate,
      pricingDetails,
      pageSubClusterId,
      paintButtonColor
    } = this.props;

    const { isLoading } = this.state;
    let hideSignupButton = false;

    let buttonTextToUse = this.getButtonText();
    const placeholderToUse = this.getPlaceholderText();
    const submitUrl = this.getSubmitUrl();
    let fullUrl = addParamToURL(submitUrl, "origin", origin);
    fullUrl = freeTierForcePrevent ? addParamToURL(fullUrl, PREVENT_FREE_TIER_PARAM, freeTierForcePrevent) : fullUrl;
    const buttonUpdatedColor = this.getButtonColor();
    let clickAction = this.signupClicked;

    // existing account flow:
    if (this.shouldReplaceGetStartedWithGoToAccount()) {
      const ctaProps = getWelcomeBackCTAProps({
        productId: this.getSignupProductId(),
        clusterId: this.getSignupClusterId(),
        subClusterId: pageSubClusterId,
        pricingDetails
      });
      // this flow disconnects the form and it's submission completely
      buttonTextToUse = translate(ctaProps.ctaTextKey);
      clickAction = () => this.gotToAccount(ctaProps.platformRedirectUrl, ctaProps.ctaTextKey);
      hideSignupButton = ctaProps.hideButton;
    }

    // Google connect
    const shouldShowVerticalGoogleConnect =
      (isMobileOrTabletBrowser && showGoogleConnectOnMobile) || forceVerticalGoogleConnect;
    const shouldShowHorizontalGoogleConnect = !isMobileOrTabletBrowser && showGoogleConnectOnDesktop;
    const renderGoogleConnect = shouldShowVerticalGoogleConnect || shouldShowHorizontalGoogleConnect;

    return (
      <div
        className={classnames("signup-form-core-component-wrapper", className, {
          error: isErrorState,
          "force-horizontal": forceHorizontal,
          "opened-input": emailInputOpened,
          "vertical-google-connect": shouldShowVerticalGoogleConnect,
          "dark-theme": displayedOnDarkTheme,
          "horizontal-google-connect": shouldShowHorizontalGoogleConnect
        })}
      >
        <ConditionalWrapper
          condition={shouldShowHorizontalGoogleConnect}
          wrapper={(children) => <div className="signup-and-google-button-wrapper">{children}</div>}
        >
          <form
            className="signup-form"
            action={fullUrl}
            data-origin={origin}
            method="POST"
            noValidate={true}
            ref={(el) => (this._form = el)}
          >
            {!this.onlyButton && (
              <EmailInput
                className={classnames("signup-input", {
                  "force-horizontal": forceHorizontal,
                  transparent: isMobileInputTransparent
                })}
                ref={(el) => (this._emailInput = el)}
                value={this.state.emailValue}
                onChange={this.updateEmail}
                placeholder={placeholderToUse}
                onKeyPress={this.submitFormIfNeeded}
                autoComplete="on"
                placeholderFocusColor={placeholderFocusColor}
                style={{ borderColor }}
              />
            )}

            <div
              className={classnames(
                "signup-button-wrapper",
                additionalText && `additional-text additional-text-${this.additionalTextPosition}`,
                { "button-with-arrow": withButtonArrow, "hide-button": hideSignupButton }
              )}
            >
              <Button
                className="signup-button"
                color={buttonUpdatedColor}
                size={buttonSize}
                isLoading={isLoading}
                onClickCallback={clickAction}
                disableAnimation={disableButtonAnimation}
                dataSpinnerColor={buttonSpinnerColor}
                invert={invert}
                hasArrow={withButtonArrow}
                paintButtonColor={paintButtonColor}
              >
                <span className="signup-text">{buttonTextToUse}</span>
              </Button>
              {!shouldShowHorizontalGoogleConnect && this.renderTextNextToButtonIfNeeded()}
            </div>
          </form>
          {renderGoogleConnect && this.renderGoogleConnect()}
        </ConditionalWrapper>

        {shouldShowHorizontalGoogleConnect && this.renderTextNextToButtonIfNeeded()}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SignupCoreComponent.defaultProps = {
  focusOnLoad: false,
  disableButtonAnimation: false,
  borderColor: "grey",
  submitUrl: NEW_SOFT_SIGNUP_LINK,
  origin: "hp_fullbg_page_header",
  forceDefaultForm: false,
  forceInputHide: false,
  withButtonArrow: false,
  useCtaText: false,
  ctaText: null,
  additionalText: false,
  additionalTextPosition: BELOW,
  additionalTextMaxWidth: null,
  buttonId: null,
  segmentNumber: null,
  isMobileInputTransparent: false,
  skipUpdateCluster: false,
  showGoogleConnectOnMobile: false,
  showGoogleConnectOnDesktop: false,
  invert: null,
  displayedOnDarkTheme: false,
  forceVerticalGoogleConnect: false,
  forceProductSignup: false,
  forceGoToMyAccount: false,
  extraData: {}
};

export default withSegmentsData(withAbTests(withGenericConfig(SignupCoreComponent)));
