import { useEffect, useState } from "react";
import LaddaButton, { XL, ZOOM_IN } from "react-ladda";
import classnames from "classnames";
import {
  SPACE_BLUE_COLOR_NAME,
  WORK_OS_IRIS_COLOR_NAME,
  GRADIANT_WORKOS_IRIS_COLOR_NAME,
  BRAND_BLACK_TEXT_COLOR,
  WORKFORMS_RED_COLOR_NAME,
  BACKGROUND_GRAY_COLOR_NAME,
  DARK_MARKETING_RED_COLOR_NAME,
  DARK_CRM_GREEN_COLOR_NAME,
  DARK_PROJECTS_ORANGE_COLOR_NAME,
  DARK_DEV_GREEN_COLOR_NAME,
  CRM_DARK_TINT_01_COLOR_NAME,
  BRAND_GREEN_DARK_TINT_01_COLOR_NAME,
  BRAND_BLACK_BACKGROUND_COLOR
} from "styles/color-consts";

import colors from "styles/colors";
import { getHoverColor } from "styles/colors-utils";
import ButtonArrow from "segments/desktop/core-components/button/button-arrow/button-arrow";
import { componentStyles, colorsStyles, fontsStyles } from "./button-component.scss";
import laddaStyles from "./button-ladda-component.scss";

export const SUPPORTED_BUTTON_COLORS = [
  "blue",
  "outline-blue",
  "outline-dark-blue",
  "outline-dark-blue-inverted-hover",
  "outline-indigo-inverted-hover",
  "brand-blue",
  "brand-red",
  "brand-green",
  "brand-yellow",
  "brand-purple",
  "brand-iris",
  "green",
  "purple",
  "iris",
  "yellow",
  "royal",
  "orange",
  "dodger-blue",
  "white",
  "white-iris", // Temporary for Google connect support during button color test.
  "white-dodger", // Temporary for Google connect support during button color test.
  BRAND_BLACK_TEXT_COLOR,
  BRAND_BLACK_BACKGROUND_COLOR,
  SPACE_BLUE_COLOR_NAME,
  WORK_OS_IRIS_COLOR_NAME,
  GRADIANT_WORKOS_IRIS_COLOR_NAME,
  WORKFORMS_RED_COLOR_NAME,
  DARK_MARKETING_RED_COLOR_NAME,
  DARK_CRM_GREEN_COLOR_NAME,
  DARK_PROJECTS_ORANGE_COLOR_NAME,
  DARK_DEV_GREEN_COLOR_NAME,
  CRM_DARK_TINT_01_COLOR_NAME,
  BRAND_GREEN_DARK_TINT_01_COLOR_NAME,
  WORK_OS_IRIS_COLOR_NAME
];

const DEFAULT_COLOR = "dodger-blue";

const ButtonComponent = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [startPaintAnimation, setStartPaintAnimation] = useState(true);
  const {
    color,
    className,
    size,
    isLoading,
    onClickCallback,
    children,
    type,
    disabled,
    disableAnimation,
    dataSpinnerColor,
    invert,
    withBorder,
    hasArrow,
    paintButtonColor
  } = props;

  const buttonColor = SUPPORTED_BUTTON_COLORS.indexOf(color) !== -1 ? color : DEFAULT_COLOR;

  useEffect(() => {
    if (paintButtonColor) {
      setStartPaintAnimation(true);
      setTimeout(() => {
        setStartPaintAnimation(false);
      }, 1000);
    }
  }, [paintButtonColor]);

  return (
    <>
      <LaddaButton
        className={classnames(`${className} ${buttonColor} ${size}`, {
          invert,
          "disable-animation": disableAnimation,
          "with-border": withBorder,
          "has-arrow": hasArrow,
          "has-paint-animation": paintButtonColor
        })}
        data-style={ZOOM_IN}
        data-size={XL}
        data-spinner-color={dataSpinnerColor}
        onClick={onClickCallback}
        type={type}
        loading={isLoading}
        disabled={disabled || isLoading}
        data-button-color={buttonColor}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {children}
        {hasArrow && <ButtonArrow size={size} hover={isHovered} isSignup={true} />}
        <style jsx global>{`
          button.ladda-button[data-button-color="${buttonColor}"] {
            &:not(.invert) {
              transition: background-color 200ms ease;
              &:hover {
                background-color: ${getHoverColor(colors[buttonColor])};
                filter: unset;
                &.white,
                &.white-iris {
                  background-color: ${colors[BACKGROUND_GRAY_COLOR_NAME]};
                }
              }
            }
          }
        `}</style>
        {paintButtonColor && (
          <span
            className={classnames("paint-overlay", { "start-animating": startPaintAnimation })}
            style={{ "--signup-paint-animation-color": colors[paintButtonColor] }}
          />
        )}

        <style jsx>{componentStyles}</style>

        <style jsx>{colorsStyles}</style>

        <style jsx>{fontsStyles}</style>

        <style jsx>{laddaStyles}</style>
      </LaddaButton>
    </>
  );
};

ButtonComponent.defaultProps = {
  isLoading: false,
  disableAnimation: false,
  onClickCallback: () => {},
  className: "",
  type: "button",
  withBorder: false
};

export default ButtonComponent;
