import css from "styled-jsx/css";
import { TABLET_QUERY_MAX_WIDTH } from "/constants/vp-sizes";

export default css`
  input {
    min-width: 240px;
    max-width: 320px;
    display: flex;
    flex-grow: 1;
    outline: none;

    &:not(.force-horizontal) {
      @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
        min-width: 120px;
        max-width: none;
        width: 100%;
      }
    }
  }
`;
