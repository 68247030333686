import { PureComponent, Fragment } from "react";
import { formStyle, inputStyle } from "./regular-signup-component.scss";
import SignupCoreComponent from "components/core/signups/base-signup-form/signup-form-core/signup-form-core-component";
import withErrorHandler from "/components/enhancements/input-error-handler/input-error-handler";

class RegularSignupComponent extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        <SignupCoreComponent {...this.props} disableButtonAnimation={true} />

        <style jsx>{formStyle}</style>

        <style jsx>{inputStyle}</style>
      </Fragment>
    );
  }
}

export default withErrorHandler(RegularSignupComponent);
