import { PureComponent } from "react";
import componentStyles from "./shake-component.scss";

export default class ShakeComponent extends PureComponent {
  render() {
    const { children, isActive } = this.props;
    return (
      <div className={`shake ${isActive ? "active" : "stop"}`}>
        {children}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

ShakeComponent.defaultProps = {
  isActive: false
};
