import css from "styled-jsx/css";

export { colorsStyles } from "./styles/colors-styles.scss";
export { fontsStyles } from "./styles/fonts-styles.scss";

export const componentStyles = css.global`
  button.ladda-button {
    padding: 16px 32px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 40px;
    border: none;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    user-select: none;
    line-height: 17px;

    &[data-style="zoom-in"] {
      &:not(:hover) {
        transition: 0.1s ease background-color;
      }
    }

    &.disable-animation {
      &.ladda-button[data-style="zoom-out"] .ladda-label {
        transition: none !important;
        z-index: 0;
      }

      &.ladda-button .ladda-label {
        transition: none !important;
        z-index: 0;
      }
    }

    &.has-arrow {
      padding: 16px 32px 16px 31px;
      &.sm {
        padding-left: 29px;
      }
    }

    &.invert {
      border-width: 1px;
      border-style: solid;
    }

    &.has-paint-animation {
      isolation: isolate; // https://stackoverflow.com/a/58283449
    }

    &:disabled {
      cursor: auto;
    }

    &:hover {
      outline: none;
    }

    img {
      vertical-align: middle;
    }

    .paint-overlay {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-radius: 50%;
      background-color: var(--signup-paint-animation-color);
      transform: translate(-50%, -50%);
      z-index: -1;
      top: 8px;
      left: calc(100% / 2);
      &.start-animating {
        animation: paintAnimation 1s ease-in-out;
      }
    }
    @keyframes paintAnimation {
      0% {
        width: 0;
        height: 0;
      }
      60% {
        width: ${`${180 * 2.25}px`};
        height: ${`${180 * 2.25}px`};
      }
      100% {
        opacity: 0;
      }
    }
  }
`;
