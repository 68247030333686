import css from "styled-jsx/css";

export const componentStyles = css`
  .picture-component {
    &.blur-image img {
      filter: blur(10px);
    }
  }

  .picture-lazy {
    opacity: 0;
  }
`;
