import css from "styled-jsx/css";
import { ARROW_TRANSITION } from "../button-consts";

export const componentStyles = css.global`
  .arrow-wrapper {
    padding-left: 12px;
    padding-right: 6px;
    transition: ${ARROW_TRANSITION};
    &.hover {
      padding-left: 18px;
      padding-right: 0px;
    }
    svg {
      fill: currentColor;
      height: 12px;
      width: 14px;
    }
  }

  .arrow-wrapper.sm.signup {
    svg {
      width: 15px;
      height: 13px;
    }
  }

  .arrow-wrapper.md.signup,
  .arrow-wrapper.lg.signup {
    svg {
      height: 12px;
      width: 16px;
    }
  }

  .arrow-wrapper.lg {
    svg {
      width: 13px;
      height: 11px;
    }
  }

  .arrow-wrapper.md {
    padding-left: 10px;
    padding-right: 5px;
    &.hover {
      padding-left: 15px;
      padding-right: 0px;
    }
    svg {
      width: 12px;
      height: 10px;
    }
  }

  .arrow-wrapper.xs {
    padding-left: 8px;
    padding-right: 4px;
    &.hover {
      padding-left: 12px;
      padding-right: 0px;
    }
    svg {
      width: 12px;
      height: 10px;
    }
  }

  .arrow-wrapper.sm {
    padding-left: 8px;
    padding-right: 4px;
    &.hover {
      padding-left: 12px;
      padding-right: 0px;
    }
    svg {
      width: 10px;
      height: 9px;
    }
  }
`;
