import BasePatternDataService from "./base-pattern-data-service";
import { NUMBER_OF_PAYING_TEAMS } from "constants/globals";

// "This is an <monday-paying-companies /> example"
const REGEX_EXPRESSION = "(<monday-paying-companies />)";

export default class PayingCompaniesPatternDataService extends BasePatternDataService {
  regex() {
    return REGEX_EXPRESSION;
  }

  isRecursive() {
    return false;
  }

  replacer(value) {
    return `${NUMBER_OF_PAYING_TEAMS}`;
  }
}
