import css from "styled-jsx/css";
import colors from "styles/colors";
import { MODAL_BACKGROUND } from "/constants/z-index";
import { LIGHT_THEME, DARK_THEME } from "./tooltip-constants";

const darkColor = colors.mud;

const lightColor = colors.white;
export const tooltipStyles = css`
  .tooltip {
    font-size: 0.8125rem;
    border-radius: 8px;
    padding: 14px;
    z-index: ${MODAL_BACKGROUND - 1};
    white-space: nowrap;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: absolute;

    &.light {
      background-color: ${lightColor};
      color: ${darkColor};
      &:after {
        border-top-color: ${lightColor};
      }
    }

    &.dark {
      background-color: ${darkColor};
      color: ${lightColor};

      &:after {
        border-top-color: ${darkColor};
      }
    }

    &:after {
      $size: 12px;
      content: "";
      position: absolute;
      left: calc(50% - #{$size / 3});
      width: 0;
      height: 0;
      bottom: 1px - $size;
      border-style: solid;
      border-width: $size $size 0 $size;
      border-color: transparent;
    }
  }
`;
