import { PureComponent } from "react";
import Shake from "/components/enhancements/shake/shake-component";
import Tooltip from "/components/enhancements/tooltip/tooltip-component";
import classnames from "classnames";
import onClickOutside from "react-onclickoutside";
import { withGenericConfig } from "/contexts/generic-config-context";

import { componentStyles } from "./input-error-handler.scss";

export default function withErrorHandler(WrappedComponent) {
  class WithErrorComponent extends PureComponent {
    constructor(props) {
      super(props);
      this.state = { error: false, shouldShake: false };
    }

    handleClickOutside = () => {
      this.setState({
        error: false,
        shouldShake: false
      });
    };

    resetShaker = () => {
      if (this.state.shouldShake) {
        setTimeout(() => this.setState({ shouldShake: false }), 600);
      }
    };

    updateErrorState = (error) => {
      this.setState({ error: error, shouldShake: error }, this.resetShaker);
    };

    renderErrorMessageIfNeeded() {
      const { preventErrorMessage, translate } = this.props;
      const { error } = this.state;

      const errorText = translate("signup.emailError");
      if (!error || preventErrorMessage) return;

      return (
        <Tooltip>
          <div className="error-tooltip-text">{errorText}</div>
        </Tooltip>
      );
    }

    render() {
      const { error, shouldShake } = this.state;

      return (
        <div className={classnames("error-handling-component")}>
          <Shake isActive={shouldShake}>
            <WrappedComponent {...this.props} isErrorState={error} errorStateCallback={this.updateErrorState} />
            {this.renderErrorMessageIfNeeded()}
          </Shake>

          <style jsx>{componentStyles}</style>
        </div>
      );
    }
  }

  return withGenericConfig(onClickOutside(WithErrorComponent));
}
