import css from "styled-jsx/css";
import ButtonColorsMapping from "/styles/button-colors-mapping";
import Colors from "/styles/colors";
import {
  WORK_OS_IRIS_COLOR_NAME,
  DODGER_BLUE_COLOR_NAME,
  GRADIANT_WORKOS_IRIS_COLOR_NAME,
  WORKFORMS_RED_COLOR_NAME,
  BRAND_BLACK_TEXT_COLOR,
  WHITE_COLOR_NAME,
  DARK_CRM_GREEN_COLOR_NAME,
  DARK_DEV_GREEN_COLOR_NAME,
  DARK_MARKETING_RED_COLOR_NAME,
  DARK_PROJECTS_ORANGE_COLOR_NAME,
  BRAND_GREEN_DARK_TINT_01_COLOR_NAME,
  CRM_DARK_TINT_01_COLOR_NAME
} from "styles/color-consts";

export const colorsStyles = css.global`
  button.ladda-button {
    &.blue {
      background-color: ${ButtonColorsMapping.blue["background-color"]};
      color: ${ButtonColorsMapping.blue.color};

      &.invert {
        color: ${ButtonColorsMapping.blue["background-color"]};
        background-color: ${ButtonColorsMapping.blue.color};
        border-color: ${ButtonColorsMapping.blue["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping.blue["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping.blue["disabled-background-color"]};
      }
    }

    &.outline-blue {
      border-width: 1px;
      border-style: solid;

      background-color: ${ButtonColorsMapping["outline-blue"]["background-color"]};
      color: ${ButtonColorsMapping["outline-blue"].color};

      &.invert {
        color: ${ButtonColorsMapping["outline-blue"]["background-color"]};
        background-color: ${ButtonColorsMapping["outline-blue"].color};
        border-color: ${ButtonColorsMapping["outline-blue"]["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping["outline-blue"]["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping["outline-blue"]["disabled-background-color"]};
      }
    }

    &.outline-dark-blue,
    &.outline-dark-blue-inverted-hover {
      border-width: 1px;
      border-style: solid;

      background-color: ${ButtonColorsMapping["outline-dark-blue"]["background-color"]};
      color: ${ButtonColorsMapping["outline-dark-blue"].color};

      &.invert {
        color: ${ButtonColorsMapping["outline-dark-blue"]["background-color"]};
        background-color: ${ButtonColorsMapping["outline-dark-blue"].color};
        border-color: ${ButtonColorsMapping["outline-dark-blue"]["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping["outline-dark-blue"]["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping["outline-dark-blue"]["disabled-background-color"]};
      }
    }

    &.outline-dark-blue-inverted-hover:hover {
      filter: unset;
      color: ${ButtonColorsMapping["outline-dark-blue"]["background-color"]};
      background-color: ${ButtonColorsMapping["outline-dark-blue"].color};
      border-color: ${ButtonColorsMapping["outline-dark-blue"]["background-color"]};
    }

    &.outline-indigo-inverted-hover {
      border-width: 1px;
      border-style: solid;

      background-color: ${ButtonColorsMapping["outline-indigo"]["background-color"]};
      color: ${ButtonColorsMapping["outline-indigo"].color};

      &.invert {
        color: ${ButtonColorsMapping["outline-indigo"]["background-color"]};
        background-color: ${ButtonColorsMapping["outline-indigo"].color};
        border-color: ${ButtonColorsMapping["outline-indigo"]["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping["outline-indigo"]["background-color"]};
        }
      }
      &:hover {
        filter: unset;
        color: ${ButtonColorsMapping["outline-indigo"]["background-color"]};
        background-color: ${ButtonColorsMapping["outline-indigo"].color};
        border-color: ${ButtonColorsMapping["outline-indigo"]["background-color"]};
      }

      &:disabled {
        background-color: ${ButtonColorsMapping["outline-indigo"]["disabled-background-color"]};
      }
    }

    &.brand-pink {
      background-color: ${ButtonColorsMapping["brand-pink"]["background-color"]};
      color: ${ButtonColorsMapping["brand-pink"].color};

      &.invert {
        color: ${ButtonColorsMapping["brand-pink"]["background-color"]};
        background-color: ${ButtonColorsMapping["brand-pink"].color};
        border-color: ${ButtonColorsMapping["brand-pink"]["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping["brand-pink"]["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping["brand-pink"]["disabled-background-color"]};
      }
    }

    &.brand-blue {
      background-color: ${ButtonColorsMapping["brand-blue"]["background-color"]};
      color: ${ButtonColorsMapping["brand-blue"].color};

      &.invert {
        color: ${ButtonColorsMapping["brand-blue"]["background-color"]};
        background-color: ${ButtonColorsMapping["brand-blue"].color};
        border-color: ${ButtonColorsMapping["brand-blue"]["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping["brand-blue"]["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping["brand-blue"]["disabled-background-color"]};
      }
    }

    &.brand-iris {
      background-color: ${ButtonColorsMapping["brand-iris"]["background-color"]};
      color: ${ButtonColorsMapping["brand-iris"].color};

      &.invert {
        color: ${ButtonColorsMapping["brand-iris"]["background-color"]};
        background-color: ${ButtonColorsMapping["brand-iris"].color};
        border-color: ${ButtonColorsMapping["brand-iris"]["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping["brand-iris"]["background-color"]};
        }
      }
    }

    &.brand-red {
      background-color: ${ButtonColorsMapping["brand-red"]["background-color"]};
      color: ${ButtonColorsMapping["brand-red"].color};

      &.invert {
        color: ${ButtonColorsMapping["brand-red"]["background-color"]};
        background-color: ${ButtonColorsMapping["brand-red"].color};
        border-color: ${ButtonColorsMapping["brand-red"]["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping["brand-red"]["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping["brand-red"]["disabled-background-color"]};
      }
    }

    &.brand-green {
      background-color: ${ButtonColorsMapping["brand-green"]["background-color"]};
      color: ${ButtonColorsMapping["brand-green"].color};

      &.invert {
        color: ${ButtonColorsMapping["brand-green"]["background-color"]};
        background-color: ${ButtonColorsMapping["brand-green"].color};
        border-color: ${ButtonColorsMapping["brand-green"]["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping["brand-green"]["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping["brand-green"]["disabled-background-color"]};
      }
    }

    &.brand-yellow {
      background-color: ${ButtonColorsMapping["brand-yellow"]["background-color"]};
      color: ${ButtonColorsMapping["brand-yellow"].color};

      &.invert {
        color: ${ButtonColorsMapping["brand-yellow"]["background-color"]};
        background-color: ${ButtonColorsMapping["brand-yellow"].color};
        border-color: ${ButtonColorsMapping["brand-yellow"]["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping["brand-yellow"]["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping["brand-yellow"]["disabled-background-color"]};
      }
    }

    &.brand-purple {
      background-color: ${ButtonColorsMapping["brand-purple"]["background-color"]};
      color: ${ButtonColorsMapping["brand-purple"].color};

      &.invert {
        color: ${ButtonColorsMapping["brand-purple"]["background-color"]};
        background-color: ${ButtonColorsMapping["brand-purple"].color};
        border-color: ${ButtonColorsMapping["brand-purple"]["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping["brand-purple"]["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping["brand-purple"]["disabled-background-color"]};
      }
    }

    &.green {
      background-color: ${ButtonColorsMapping.green["background-color"]};
      color: ${ButtonColorsMapping.green.color};

      &.invert {
        color: ${ButtonColorsMapping.green["background-color"]};
        background-color: ${ButtonColorsMapping.green.color};
        border-color: ${ButtonColorsMapping.green["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping.green["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping.green["disabled-background-color"]};
      }
    }

    &.purple {
      background-color: ${ButtonColorsMapping.purple["background-color"]};
      color: ${ButtonColorsMapping.purple.color};

      &.invert {
        color: ${ButtonColorsMapping.purple["background-color"]};
        background-color: ${ButtonColorsMapping.purple.color};
        border-color: ${ButtonColorsMapping.purple["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping.purple["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping.purple["disabled-background-color"]};
      }
    }

    &.iris {
      background-color: ${ButtonColorsMapping.iris["background-color"]};
      color: ${ButtonColorsMapping.iris.color};

      &.invert {
        color: ${ButtonColorsMapping.iris["background-color"]};
        background-color: ${ButtonColorsMapping.iris.color};
        border-color: ${ButtonColorsMapping.iris["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping.iris["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping.iris["disabled-background-color"]};
      }
    }

    &.yellow {
      background-color: ${ButtonColorsMapping.yellow["background-color"]};
      color: ${ButtonColorsMapping.yellow.color};

      &.invert {
        color: ${ButtonColorsMapping.yellow["background-color"]};
        background-color: ${ButtonColorsMapping.yellow.color};
        border-color: ${ButtonColorsMapping.yellow["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping.yellow["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping.yellow["disabled-background-color"]};
      }
    }

    &.orange {
      background-color: ${ButtonColorsMapping.orange["background-color"]};
      color: ${ButtonColorsMapping.orange.color};

      &.invert {
        color: ${ButtonColorsMapping.orange["background-color"]};
        background-color: ${ButtonColorsMapping.orange.color};
        border-color: ${ButtonColorsMapping.orange["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping.orange["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping.orange["disabled-background-color"]};
      }
    }

    &.pink {
      background-color: ${ButtonColorsMapping.pink["background-color"]};
      color: ${ButtonColorsMapping.pink.color};

      &.invert {
        color: ${ButtonColorsMapping.pink["background-color"]};
        background-color: ${ButtonColorsMapping.pink.color};
        border-color: ${ButtonColorsMapping.pink["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping.pink["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping.pink["disabled-background-color"]};
      }
    }

    &.royal {
      background-color: ${ButtonColorsMapping.royal["background-color"]};
      color: ${ButtonColorsMapping.royal.color};

      &.invert {
        color: ${ButtonColorsMapping.royal["background-color"]};
        background-color: ${ButtonColorsMapping.royal.color};
        border-color: ${ButtonColorsMapping.royal["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping.royal["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping.royal["disabled-background-color"]};
      }
    }

    &.dodger-blue {
      background-color: ${ButtonColorsMapping["dodger-blue"]["background-color"]};
      color: ${ButtonColorsMapping["dodger-blue"].color};

      &.invert {
        color: ${ButtonColorsMapping["dodger-blue"]["background-color"]};
        background-color: ${ButtonColorsMapping["dodger-blue"].color};
        border-color: ${ButtonColorsMapping["dodger-blue"]["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping["dodger-blue"]["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping["dodger-blue"]["disabled-background-color"]};
      }
    }

    &.crm-dark-tint-01 {
      background-color: ${Colors[CRM_DARK_TINT_01_COLOR_NAME]};
      color: ${Colors.white};

      &.invert {
        color: ${Colors[CRM_DARK_TINT_01_COLOR_NAME]};
        background-color: transparent;
        border-color: ${Colors[CRM_DARK_TINT_01_COLOR_NAME]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${Colors[CRM_DARK_TINT_01_COLOR_NAME]};
        }
      }

      &:disabled {
        background-color: ${Colors[CRM_DARK_TINT_01_COLOR_NAME]};
      }
    }

    &.brand-green-dark-tint-01 {
      background-color: ${Colors[BRAND_GREEN_DARK_TINT_01_COLOR_NAME]};
      color: ${Colors.white};

      &.invert {
        color: ${Colors[BRAND_GREEN_DARK_TINT_01_COLOR_NAME]};
        background-color: transparent;
        border-color: ${Colors[BRAND_GREEN_DARK_TINT_01_COLOR_NAME]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${Colors[BRAND_GREEN_DARK_TINT_01_COLOR_NAME]};
        }
      }

      &:disabled {
        background-color: ${Colors[BRAND_GREEN_DARK_TINT_01_COLOR_NAME]};
      }
    }

    &.workos-iris {
      background-color: ${Colors[WORK_OS_IRIS_COLOR_NAME]};
      color: ${Colors.white};

      &.invert {
        color: ${Colors[WORK_OS_IRIS_COLOR_NAME]};
        background-color: transparent;
        border-color: ${Colors[WORK_OS_IRIS_COLOR_NAME]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${Colors[WORK_OS_IRIS_COLOR_NAME]};
        }
      }

      &:disabled {
        background-color: ${Colors[WORK_OS_IRIS_COLOR_NAME]};
      }
    }

    &.marketing-red-dark {
      background-color: ${Colors[DARK_MARKETING_RED_COLOR_NAME]};
      color: ${Colors.white};

      &.invert {
        color: ${Colors[DARK_MARKETING_RED_COLOR_NAME]};
        background-color: transparent;
        border-color: ${Colors[DARK_MARKETING_RED_COLOR_NAME]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${Colors[DARK_MARKETING_RED_COLOR_NAME]};
        }
      }

      &:disabled {
        background-color: ${Colors[DARK_MARKETING_RED_COLOR_NAME]};
      }
    }

    &.crm-green-dark {
      background-color: ${Colors[DARK_CRM_GREEN_COLOR_NAME]};
      color: ${Colors.white};

      &.invert {
        color: ${Colors[DARK_CRM_GREEN_COLOR_NAME]};
        background-color: transparent;
        border-color: ${Colors[DARK_CRM_GREEN_COLOR_NAME]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${Colors[DARK_CRM_GREEN_COLOR_NAME]};
        }
      }

      &:disabled {
        background-color: ${Colors[DARK_CRM_GREEN_COLOR_NAME]};
      }
    }

    &.projects-orange-dark {
      background-color: ${Colors[DARK_PROJECTS_ORANGE_COLOR_NAME]};
      color: ${Colors.white};

      &.invert {
        color: ${Colors[DARK_PROJECTS_ORANGE_COLOR_NAME]};
        background-color: transparent;
        border-color: ${Colors[DARK_PROJECTS_ORANGE_COLOR_NAME]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${Colors[DARK_PROJECTS_ORANGE_COLOR_NAME]};
        }
      }

      &:disabled {
        background-color: ${Colors[DARK_PROJECTS_ORANGE_COLOR_NAME]};
      }
    }

    &.dev-green-dark {
      background-color: ${Colors[DARK_DEV_GREEN_COLOR_NAME]};
      color: ${Colors.white};

      &.invert {
        color: ${Colors[DARK_DEV_GREEN_COLOR_NAME]};
        background-color: transparent;
        border-color: ${Colors[DARK_DEV_GREEN_COLOR_NAME]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${Colors[DARK_DEV_GREEN_COLOR_NAME]};
        }
      }

      &:disabled {
        background-color: ${Colors[DARK_DEV_GREEN_COLOR_NAME]};
      }
    }

    &.space-blue {
      background-color: ${ButtonColorsMapping["space-blue"]["background-color"]};
      color: ${ButtonColorsMapping["space-blue"].color};

      &.invert {
        color: ${ButtonColorsMapping["space-blue"]["background-color"]};
        background-color: ${ButtonColorsMapping["space-blue"].color};
        border-color: ${ButtonColorsMapping["space-blue"]["background-color"]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${ButtonColorsMapping["space-blue"]["background-color"]};
        }
      }

      &:disabled {
        background-color: ${ButtonColorsMapping["space-blue"]["disabled-background-color"]};
      }
    }

    &.black,
    &.Black {
      background-color: ${Colors.Black};
      color: ${Colors.white};

      &.invert {
        color: ${Colors.black};
        background-color: ${Colors.white};
        border-color: ${Colors.black};
        &:hover {
          box-shadow: inset 0 0 0 1px ${Colors.black};
        }
      }

      &:disabled {
        background-color: ${Colors.Black};
      }
    }

    &.white {
      background-color: ${Colors[WHITE_COLOR_NAME]};
      color: ${Colors[BRAND_BLACK_TEXT_COLOR]};

      &.invert {
        color: ${Colors[WHITE_COLOR_NAME]};
        background-color: transparent;
        border-color: ${Colors[WHITE_COLOR_NAME]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${Colors[WHITE_COLOR_NAME]};
        }
      }

      &:disabled {
        background-color: ${Colors.white};
      }
    }

    &.white-iris {
      background-color: ${Colors.white};
      color: ${Colors[WORK_OS_IRIS_COLOR_NAME]};

      &.invert {
        color: ${Colors.white};
        background-color: ${Colors[WORK_OS_IRIS_COLOR_NAME]};
        border-color: ${Colors.white};
        &:hover {
          box-shadow: inset 0 0 0 1px ${Colors.white};
        }
      }

      &:disabled {
        background-color: ${Colors.white};
      }
    }

    &.gradiant-workos-iris {
      background: ${Colors[GRADIANT_WORKOS_IRIS_COLOR_NAME]};
      color: ${Colors.white};

      &.invert {
        color: ${Colors[GRADIANT_WORKOS_IRIS_COLOR_NAME]};
        background: ${Colors.white};
        border-color: ${Colors[GRADIANT_WORKOS_IRIS_COLOR_NAME]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${Colors[GRADIANT_WORKOS_IRIS_COLOR_NAME]};
        }
      }

      &:disabled {
        background: ${Colors[GRADIANT_WORKOS_IRIS_COLOR_NAME]};
      }
    }

    &.white-dodger {
      background-color: ${Colors.white};
      color: ${Colors[DODGER_BLUE_COLOR_NAME]};

      &.invert {
        color: ${Colors.white};
        background-color: ${Colors[DODGER_BLUE_COLOR_NAME]};
        border-color: ${Colors.white};
        &:hover {
          box-shadow: inset 0 0 0 1px ${Colors.white};
        }
      }

      &:disabled {
        background-color: ${Colors.white};
      }
    }

    &.workforms-red {
      background-color: ${Colors[WORKFORMS_RED_COLOR_NAME]};
      color: ${Colors.white};

      &.invert {
        color: ${Colors[WORKFORMS_RED_COLOR_NAME]};
        background-color: ${Colors.white};
        border-color: ${Colors[WORKFORMS_RED_COLOR_NAME]};
        &:hover {
          box-shadow: inset 0 0 0 1px ${Colors.black};
        }
      }

      &:disabled {
        background-color: ${Colors[WORKFORMS_RED_COLOR_NAME]};
      }
    }

    &.with-border {
      border-width: 1px;
      border-style: solid;
    }
  }
`;
