export default class BasePatternDataService {
  constructor() {}

  regex() {
    return "";
  }

  isRecursive() {
    return false;
  }

  patternCharsCount() {
    return 0;
  }

  replacer() {
    return null;
  }
}
