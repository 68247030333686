import css from "styled-jsx/css";
import colors from "/styles/colors";
import { TABLET_QUERY_MAX_WIDTH } from "/constants/vp-sizes";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import { ARROW_TRANSITION } from "segments/desktop/core-components/button/button-consts";

export const formStyle = css.global`
  .signup-form-core-component-wrapper {
    form {
      flex-wrap: wrap;
      display: inline-flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }
    .phoneno {
      display: none;
    }

    &.align-left {
      form {
        justify-content: flex-start;
      }
    }

    &.hide-form {
      visibility: hidden;
    }

    &:not(.force-horizontal) {
      @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
        form {
          flex-direction: column;
        }
      }
    }
  }
`;

export const inputStyle = css.global`
  .signup-form-core-component-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    input {
      font-size: 0.875rem;
      line-height: 8px;
      padding: 12px 24px;
      border-radius: 32px;

      -webkit-appearance: none; //Remove shadow from input in mobile safari

      background-color: ${colors["light-grey"]};
      color: ${colors["black"]};

      border: 1px solid;
      border-color: ${colors["grey"]};

      text-align: center;
      transition: border-color 0.1s ease-in-out;
      margin: 8px 16px 8px 0;

      &:hover::placeholder,
      &:focus::placeholder {
        color: ${colors[WORK_OS_IRIS_COLOR_NAME]};
      }

      &:focus {
        border-color: ${colors[WORK_OS_IRIS_COLOR_NAME]};
        background-color: ${colors["white"]};
      }

      &.transparent {
        background-color: rgba(255, 255, 255, 0.1);

        color: ${colors["white"]};
        &::placeholder {
          color: ${colors["white"]};
          opacity: 0.8;
        }
      }
    }

    &.error input,
    &.error input:focus {
      border-color: ${colors["error"]};
    }

    button.ladda-button.sm {
      font-size: 0.875rem;
    }

    .only-button {
      min-width: 300px;
    }

    .signup-button-wrapper {
      .signup-button {
        min-width: 130px;

        &.secondary-button {
          @media (max-width: 1058px) {
            margin-top: 8px;
          }

          @media (min-width: 1058px) {
            margin-left: 24px;
          }
        }
      }

      &.button-with-arrow {
        .signup-button {
          padding-right: 20px;
          &.xs {
            padding-right: 14px;
          }
          &.xxs {
            padding-right: 8px;
            padding-left: 8px;
            font-size: 0.6875rem;
            padding-top: 8px;
            padding-bottom: 8px;
          }
        }
      }
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .signup-form-core-component-wrapper {
      &:not(.force-horizontal) {
        text-align: center;
        input {
          margin-bottom: 16px;
          font-size: 0.875rem;
          padding: 16px 8px;
          margin-right: 0;
          min-width: 264px;
        }

        .signup-button-wrapper {
          width: 100%;
          button {
            font-size: 0.875rem;
            padding: 16px 48px;
            width: 100%;
          }
        }
        &.button-with-arrow {
          button.signup-button {
            padding-right: 32px;
          }
        }
      }
    }
  }
`;
