import { getCookie, setCookie } from "/services/cookies-service";
import {
  LAST_ACCOUNT_SLUG,
  WELCOME_BACK_SHOULD_UPDATE_CTA_COOKIE_NAME,
  PLATFORM_WEBSITE_EXISTING_ACCOUNT_HEADER_TEST_COOKIE
} from "constants/cookies";
import * as Sentry from "@sentry/nextjs";
import { shouldGetWelcomeBackPageTest } from "services/ab-tests/acquisition-ab-test-service";
import { isRunningInClient } from "services/dom-utils-service";
import { trackEvent } from "/services/bigbrain-service";
import {
  getPlatformProductID,
  WORK_MANAGEMENT_PRODUCT_ID,
  shouldGetProductSignup,
  getProductSignupURL
} from "constants/products";
import { addParamToURL } from "utils/url";
import { PLATFORM_PERIODS, TIERS } from "constants/pricing";
import { HR, OPERATIONS, EDUCATION, CONSTRUCTION, NON_PROFIT, WORK_DOCS_SUB_CLUSTER, IT } from "constants/clusters";
import { NEW_SOFT_SIGNUP_LINK } from "constants/links";
import { PLATFORM_EXISTING_ACCOUNT_HEADER_TEST } from "constants/new-ab-tests";

export const ACTIVE_ACCOUNTS_SLUGS_COOKIE_NAME = "monday_active_account_slugs"; // set in the platform after each login expiry 1 year
const ALL_SLUGS_COOKIE_NAME = "dapulseAccountSlugs"; // set in the platform after each login - session cookie no expiration
export const SLUG_DETAILS_COOKIE_NAME = "monday_slug_details"; // set in the platform after each login - saves up to 3 last slugs

const WELCOME_BACK_COOKIE_EXPIRATION_DAYS = 30;
export const DEFAULT_PLATFORM_USER_IMAGE_SOURCE = "https://cdn1.monday.com/dapulse_default_photo.png";

// Payment form params
const PAYMENT_TIER_PARAM = "tier";
const PAYMENT_PERIOD_PARAM = "period";
const PAYMENT_USERS_COUNT_PARAM = "users_count";
const PAYMENT_SOURCE_PARAM = "source";

// Returns array of slug names (string)
export const getMondayActiveSlugs = () => {
  let slugsFromCookie = getCookie(ACTIVE_ACCOUNTS_SLUGS_COOKIE_NAME);
  let slugs = [];
  if (slugsFromCookie) {
    try {
      slugsFromCookie = decodeURIComponent(slugsFromCookie);
      slugs = JSON.parse(slugsFromCookie);
    } catch (err) {
      Sentry.captureException(err);
    }
  }
  return slugs;
};

export const getMondaySlugsDetails = () => {
  let slugsFromCookie = getCookie(SLUG_DETAILS_COOKIE_NAME);
  let parsedSlugsDetails = [];
  if (slugsFromCookie) {
    try {
      slugsFromCookie = decodeURIComponent(slugsFromCookie);
      const slugsDetails = JSON.parse(slugsFromCookie);
      const fixedSlugDetails = slugsDetails.filter((slugDetail) => slugDetail.account_name || slugDetail.an); // fixes a bug where empty slug details were saved
      parsedSlugsDetails = fixedSlugDetails.map((slugDetail) => parseSingleAccountSlugDetails(slugDetail));
    } catch (err) {
      Sentry.captureException(err);
    }
  }
  return parsedSlugsDetails;
};

export const getLastUsedAccountSlug = () => {
  return getCookie(LAST_ACCOUNT_SLUG);
};

export const getMondayFullUrlForSlug = (slug) => {
  return `//${slug}.${process.env.NEXT_PUBLIC_AUTH_PLATFORM_DOMAIN}`; // works also in client side (next trick)
};

export const validateAllAccountsCreatedAfterDate = (date) => {
  try {
    const slugs = getMondaySlugsDetails();
    const newSlugs = slugs.filter((slug) => slug.ac && new Date(slug.ac) >= date);
    return newSlugs.length === slugs.length;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
};

export const isExistingUserInWelcomeBackTest = (abTests, persistantCookies = {}) => {
  return (
    shouldGetWelcomeBackPageTest(abTests, persistantCookies) &&
    persistantCookies[WELCOME_BACK_SHOULD_UPDATE_CTA_COOKIE_NAME] === true
  );
};

export const isExistingUserInHeaderTest = (persistantCookies = {}, fireAbTest = false) => {
  try {
    const variant = persistantCookies[PLATFORM_WEBSITE_EXISTING_ACCOUNT_HEADER_TEST_COOKIE];
    if (variant && fireAbTest && isRunningInClient()) {
      const lastSlug = getLastUsedAccountSlug();
      const slugsDetailsFromCookie = getMondaySlugsDetails();

      if (!lastSlug || !slugsDetailsFromCookie) return false; // something problematic with the cookies revert to regular header

      const relevantSlugDetails = slugsDetailsFromCookie.find((slugDetails) => slugDetails.sl === lastSlug);
      const pulseUserId = relevantSlugDetails?.ui;
      const pulseAccountId = relevantSlugDetails?.ai;

      if (pulseUserId && pulseAccountId) {
        trackEvent(PLATFORM_EXISTING_ACCOUNT_HEADER_TEST.test_name, {
          ab_test: variant,
          pulse_user_id: pulseUserId,
          pulse_account_id: pulseAccountId
        });
      }
    }

    return variant === PLATFORM_EXISTING_ACCOUNT_HEADER_TEST.new_variation_name;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
};

export const setWelcomeBackCookie = () => {
  if (!getCookie(LAST_ACCOUNT_SLUG)) {
    // LAST_ACCOUNT_SLUG isn't in cloudfront cache - but this code runs on client side
    Sentry.captureMessage("Attempt to set Welcome back cookie on account without dapulseLastLoginAccount cookie");
    return;
  }

  setCookie(WELCOME_BACK_SHOULD_UPDATE_CTA_COOKIE_NAME, "true", { expires: WELCOME_BACK_COOKIE_EXPIRATION_DAYS });
};

const getPlatformTemplateCenterPageSuffix = (clusterId, subClusterId) => {
  const CLUSTER_TEMPLATE_CENTER_LINKS = {
    [HR]: "general__hr",
    [OPERATIONS]: "general__office_operations",
    [IT]: "general__office_operations",
    [EDUCATION]: "general__education",
    [CONSTRUCTION]: "general__construction",
    [NON_PROFIT]: "general__nonprofits"
  };

  const SUBCLUSTER_TEMPLATE_CENTER_LINKS = {
    [WORK_DOCS_SUB_CLUSTER]: "general__docs"
  };

  const templateCenterSuffix =
    CLUSTER_TEMPLATE_CENTER_LINKS[clusterId] || SUBCLUSTER_TEMPLATE_CENTER_LINKS[subClusterId];
  if (templateCenterSuffix) return `template_center/category/${templateCenterSuffix}`;

  return null;
};

export const getWelcomeBackCTAProps = ({ productId, clusterId, subClusterId, pricingDetails }) => {
  let ctaTextKey = "existingAccount.mainCta";
  const lastSlug = getCookie(LAST_ACCOUNT_SLUG);
  let platformRedirectUrl = getMondayFullUrlForSlug(lastSlug);
  // COMMENTING OUT MULTIPLE CTA OPTIONS FOR WELCOME BACK - WILL RETURN IN FOLLOW UP TEST
  // Since work management is installed by default no need to "try it out"
  // if (productId && productId !== WORK_MANAGEMENT_PRODUCT_ID) {
  //   ctaTextKey = "existingAccount.installProduct";
  //   const platformProductId = getPlatformProductID(productId);
  //   platformRedirectUrl = `${platformRedirectUrl}/product_store/${platformProductId}`;
  // } else if (pricingDetails) {
  //   if (pricingDetails?.tier === TIERS.FREE) {
  //     return { hideButton: true };
  //   }

  //   // pricing but not for products
  //   ctaTextKey = "existingAccount.pricingCta";
  //   platformRedirectUrl = `${platformRedirectUrl}/open_payment_form`;
  //   platformRedirectUrl = addParamToURL(platformRedirectUrl, PAYMENT_TIER_PARAM, pricingDetails?.tier);
  //   platformRedirectUrl = addParamToURL(
  //     platformRedirectUrl,
  //     PAYMENT_PERIOD_PARAM,
  //     PLATFORM_PERIODS[pricingDetails?.billingPeriod]
  //   );
  //   platformRedirectUrl = addParamToURL(platformRedirectUrl, PAYMENT_USERS_COUNT_PARAM, pricingDetails?.userCount);
  //   platformRedirectUrl = addParamToURL(platformRedirectUrl, PAYMENT_SOURCE_PARAM, "website");
  // } else {
  //   const templateCenterLink = getPlatformTemplateCenterPageSuffix(clusterId, subClusterId);
  //   if (templateCenterLink) {
  //     ctaTextKey = "existingAccount.viewTemplates";
  //     platformRedirectUrl = `${platformRedirectUrl}/${templateCenterLink}`;
  //   }
  // }

  return { ctaTextKey, platformRedirectUrl };
};

const parseSingleAccountSlugDetails = (slugsDetailsFromCookie) => {
  if (!slugsDetailsFromCookie) return {};
  // Transforms cookie structure to the new short 2 letter key strcuture (old data is still with the long key names)
  return {
    ...slugsDetailsFromCookie, // for new keys
    un: slugsDetailsFromCookie.un || slugsDetailsFromCookie.user_name,
    ue: slugsDetailsFromCookie.ue || slugsDetailsFromCookie.user_email,
    ui: slugsDetailsFromCookie.ui || slugsDetailsFromCookie.user_id,
    us: slugsDetailsFromCookie.us || slugsDetailsFromCookie.user_serial_number,
    up: slugsDetailsFromCookie.up || slugsDetailsFromCookie.user_image || DEFAULT_PLATFORM_USER_IMAGE_SOURCE,
    an: slugsDetailsFromCookie.an || slugsDetailsFromCookie.account_name,
    ac: slugsDetailsFromCookie.ac || slugsDetailsFromCookie.account_created,
    ai: slugsDetailsFromCookie.ai || slugsDetailsFromCookie.account_id,
    sl: slugsDetailsFromCookie.sl || slugsDetailsFromCookie.slug,
    lu: slugsDetailsFromCookie.lu || slugsDetailsFromCookie.last_used
  };
};

export const getSignupLink = (pageProductId) => {
  if (pageProductId && shouldGetProductSignup(pageProductId)) {
    return getProductSignupURL(pageProductId);
  }
  return NEW_SOFT_SIGNUP_LINK;
};
