import { createContext, forwardRef, PureComponent } from "react";

export const AbTestsContext = createContext(null);

export const withAbTests = function (WrappedComponent) {
  class AbTestsComponent extends PureComponent {
    constructor(props) {
      super(props);
      this.getAbTestsFromValue = this.getAbTestsFromValue.bind(this);
      this.getAbTestExactEventNameFromValue = this.getAbTestExactEventNameFromValue.bind(this);
    }

    getAbTestsFromValue(value) {
      return (value && value.abTestVariants) || [];
    }

    getAbTestExactEventNameFromValue(value) {
      return (value && value.abTestExactEventName) || null;
    }

    render() {
      const { forwardedRef, ...rest } = this.props;

      return (
        <AbTestsContext.Consumer>
          {(value) => (
            <WrappedComponent
              ref={forwardedRef}
              {...rest}
              abTests={this.getAbTestsFromValue(value)}
              abTestExactEventName={this.getAbTestExactEventNameFromValue(value)}
            />
          )}
        </AbTestsContext.Consumer>
      );
    }
  }

  return forwardRef((props, ref) => {
    return <AbTestsComponent {...props} forwardedRef={ref} />;
  });
};
