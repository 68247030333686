import { createContext, forwardRef, PureComponent } from "react";

export const GenericConfigContext = createContext(null);

export const withGenericConfig = function (WrappedComponent) {
  class GenericConfigComponent extends PureComponent {
    constructor(props) {
      super(props);
    }

    render() {
      const { forwardedRef, ...rest } = this.props;

      return (
        <GenericConfigContext.Consumer>
          {(value) => <WrappedComponent ref={forwardedRef} {...value} {...rest} />}
        </GenericConfigContext.Consumer>
      );
    }
  }

  return forwardRef((props, ref) => {
    return <GenericConfigComponent {...props} forwardedRef={ref} />;
  });
};
