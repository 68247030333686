import PatternDataServices from "./pattern-data-services";

const buildRegexPattern = () => {
  return PatternDataServices.map(patternDataService => patternDataService.regex()).join("|");
};

export const parseText = text => {
  try {
    const regexPatterns = buildRegexPattern();
    const regex = new RegExp(regexPatterns);
    let finalText = "";
    let result = "";

    while ((result = text.match(regex))) {
      PatternDataServices.forEach((patternDataService, index) => {
        const group = index + 1;
        const value = result[group];

        if (value) {
          const data = patternDataService.isRecursive() ? parseText(value) : value;
          finalText += text.substring(0, result["index"]) + patternDataService.replacer(data);
          const offset = Math.min(result["index"] + value.length + patternDataService.patternCharsCount(), text.length);
          text = text.substring(offset, text.length);
        }
      });
    }

    return finalText + text;
  } catch (error) {
    console.error("Error in parseText:", error);
    return text;
  }
};
