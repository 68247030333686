import { PureComponent } from "react";
import { generateOnKeyDownHandler, preventFocusOnMouseDown } from "client/services/accessibility/accessibility-service";
import { withGenericConfig } from "/contexts/generic-config-context";
import componentStyles from "./hamburger-menu-component.scss";

class HamburgerMenuComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.toggleOpenState = this.toggleOpenState.bind(this);
    this.onKeyDown = generateOnKeyDownHandler(this.toggleOpenState);
  }

  toggleOpenState() {
    const { isOpen } = this.props;
    this.props.changeStateCallback(!isOpen);
  }

  render() {
    const { theme, isInScrollMode, isOpen, translate } = this.props;
    const scrollModeClass = isInScrollMode ? "scroll-mode" : "";
    const openMode = isOpen ? "open" : "";

    return (
      <div
        className={`hamburger-menu ${theme} ${scrollModeClass} ${openMode}`}
        tabIndex={0}
        onClick={this.toggleOpenState}
        onKeyDown={this.onKeyDown}
        onMouseDown={preventFocusOnMouseDown}
        aria-label={isOpen ? translate("header.closeMenu") : translate("header.openMenu")}
        aria-haspopup="menu"
        aria-expanded={isOpen}
        role="button"
      >
        <span className="hamburger-menu-line" />
        <span className="hamburger-menu-line" />
        <span className="hamburger-menu-line" />
        <span className="hamburger-menu-line" />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

HamburgerMenuComponent.defaultProps = {
  changeStateCallback: () => {},
  isInScrollMode: false,
  theme: "",
  isOpen: false
};

export default withGenericConfig(HamburgerMenuComponent);
