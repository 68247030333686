export const REGULAR_BUTTON_TYPE = "Regular";
export const SECONDARY_BUTTON_TYPE = "Secondary";
export const SIGNUP_BUTTON_TYPE = "Signup";
export const TWO_LINK_BUTTONS_TYPE = "ButtonsCombo";
export const SIGNUP_COMBO_BUTTONS_TYPE = "SignupButtonsCombo";
export const SUBSCRIBE_BUTTON_TYPE = "Subscribe";
export const YOUTUBE_POPUP_LINK_TYPE = "YouTube Popup";
export const SIGNUP_AND_YOUTUBE_COMBO_BUTTONS_TYPE = "SignupAndYoutubeComboButtonsCombo";

const TRANSITION_DURATION = `0.3s`;
export const UNDERLINE_TRANSITION = `background-size ${TRANSITION_DURATION}`;
export const ARROW_TRANSITION = `padding ${TRANSITION_DURATION} ease-in-out`;
