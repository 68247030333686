export const getAppIconsLinks = () => {
  return [
    <link rel="shortcut icon" href="/static/img/favicons/favicon.ico" />,
    <link rel="icon" sizes="16x16 32x32 64x64" href="/static/img/favicons/favicon.ico" />,
    <link rel="icon" type="image/png" sizes="196x196" href="/static/img/favicons/favicon-monday5-192.png" />,
    <link rel="icon" type="image/png" sizes="96x96" href="/static/img/favicons/favicon-monday5-96.png" />,
    <link rel="icon" type="image/png" sizes="64x64" href="/static/img/favicons/favicon-monday5-60.png" />,
    <link rel="icon" type="image/png" sizes="32x32" href="/static/img/favicons/favicon-monday5-32.png" />,
    <link rel="icon" type="image/png" sizes="16x16" href="/static/img/favicons/favicon-monday5-16.png" />,
    <link rel="apple-touch-icon" href="/static/img/favicons/favicon-monday5-57.png" />,
    <link rel="apple-touch-icon" sizes="114x114" href="/static/img/favicons/favicon-monday5-114.png" />,
    <link rel="apple-touch-icon" sizes="72x72" href="/static/img/favicons/favicon-monday5-72.png" />,
    <link rel="apple-touch-icon" sizes="144x144" href="/static/img/favicons/favicon-monday5-144.png" />,
    <link rel="apple-touch-icon" sizes="60x60" href="/static/img/favicons/favicon-monday5-60.png" />,
    <link rel="apple-touch-icon" sizes="120x120" href="/static/img/favicons/favicon-monday5-120.png" />,
    <link rel="apple-touch-icon" sizes="76x76" href="/static/img/favicons/favicon-monday5-76.png" />,
    <link rel="apple-touch-icon" sizes="152x152" href="/static/img/favicons/favicon-monday5-152.png" />,
    <link rel="apple-touch-icon" sizes="180x180" href="/static/img/favicons/favicon-monday5-180.png" />,
    <meta name="msapplication-TileColor" content="#FFFFFF" />,
    <meta name="msapplication-TileImage" content="/static/img/favicons/favicon-monday5-144.png" />,
    <link rel="icon" type="image/x-icon" href="/static/img/favicons/favicon.ico" />
  ];
};
