import css from "styled-jsx/css";
import Colors from "styles/colors";

export const componentStyles = css.global`
  .signup-form-core-component-wrapper {
    .additional-button-text {
      font-style: normal;
      font-weight: normal;
      font-size: 0.8125rem;
      line-height: 18px;
      color: ${Colors.asphalt};

      &.below {
        margin-top: 8px;
        text-align: center;
      }

      &.side {
        margin-left: 16px;
        text-align: left;
      }
    }

    .signup-button-wrapper {
      &.additional-text {
        display: flex;
        align-items: center;

        &.additional-text-below {
          flex-direction: column;
        }

        &.additional-text-side {
          flex-direction: row;
        }
      }
      &.hide-button {
        visibility: hidden;
      }
    }

    &.vertical-google-connect {
      display: flex;
      flex-direction: column;
      min-width: 305px;

      .social-connect-section {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .google-separator {
          display: flex;
          align-items: center;
          margin: 16px 0;
          .seperator-line {
            flex-grow: 1;
            border-color: ${Colors["wolf-gray"]};
            border-top-width: 0.5px;
            border-bottom-width: 0.5px;
            border-style: solid;
            opacity: 0.4;
          }
          .separator-text {
            padding-left: 16px;
            padding-right: 16px;
            font-size: 0.8125rem;
            line-height: 24px;
            color: ${Colors.asphalt};
          }
        }
        .google-connect-button {
          padding-top: 12px;
          padding-bottom: 12px;

          .google-button-inner {
            display: flex;
            justify-content: center;
            .continue-text {
              display: flex;
              align-items: center;
              font-size: 0.875rem;
            }
            .google-icon {
              width: 24px;
              height: 24px;
              margin-left: 16px;
            }
          }
        }
      }
    }

    &.horizontal-google-connect {
      flex-direction: column;
      align-items: center;

      .signup-and-google-button-wrapper {
        display: flex;

        .google-connect-button-wrapper {
          margin-left: 16px;
          .google-connect-button {
            padding-top: 12px;
            padding-bottom: 12px;
            width: 220px;

            .google-button-inner {
              display: flex;
              justify-content: center;
              .continue-text {
                display: flex;
                align-items: center;
                font-size: 0.875rem;
              }
              .google-icon {
                width: 24px;
                height: 24px;
                margin-left: 16px;
              }
            }
          }
        }
      }
    }

    &.dark-theme {
      .additional-button-text {
        color: ${Colors.white};
      }
      .social-connect-section {
        .google-separator {
          .separator-text {
            color: ${Colors.white};
          }
        }
        .google-connect-button {
          border: none;
        }
      }
    }
  }
`;
