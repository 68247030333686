import { PureComponent } from "react";
import classnames from "classnames";
import { componentStyle, TRANSITION_TIMING_MILISECONDS } from "./expanding-signup-component.scss";
import { SIGNUP_FORM_GET_STARTED_CLICKED } from "/constants/bigbrain-event-types";
import colors from "/styles/colors";
import { TABLET_QUERY_MAX_WIDTH_MEDIA_QUERY } from "/constants/vp-sizes";
import SignupCoreComponent from "components/core/signups/base-signup-form/signup-form-core/signup-form-core-component";
import { withGenericConfig } from "/contexts/generic-config-context";
import withErrorHandler from "/components/enhancements/input-error-handler/input-error-handler";

const SECOND_CTA_KEY = "signup.secondCta";

class ExpandingSignupComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { emailInputOpened: props.emailInputOpened, useSecondCTA: false };
    this._signupComponent = null;
  }

  componentDidMount() {
    if (window.matchMedia(TABLET_QUERY_MAX_WIDTH_MEDIA_QUERY).matches) {
      this.setState({ emailInputOpened: true });
    }
  }

  handleShowInput = () => {
    const { placement } = this.props;
    const transitionTime = TRANSITION_TIMING_MILISECONDS;

    BigBrain("track", SIGNUP_FORM_GET_STARTED_CLICKED, { placement });
    this.setState({ emailInputOpened: true, useSecondCTA: true }, () =>
      setTimeout(() => {
        this._signupComponent.focusOnInput();
      }, transitionTime)
    );
  };

  getSecondCtaButtonText() {
    const { translate } = this.props;
    return translate(SECOND_CTA_KEY); //removed from default props because of localization
  }

  render() {
    const { buttonColor, alignCenter } = this.props;
    const { emailInputOpened, useSecondCTA } = this.state;

    const onClick = emailInputOpened ? null : this.handleShowInput;
    const ctaText = useSecondCTA ? this.getSecondCtaButtonText() : null;

    const borderColor = colors[buttonColor];
    return (
      <div className={classnames("signup-form-core-outer-wrapper", { "align-center": alignCenter })}>
        <SignupCoreComponent
          ref={(el) => (this._signupComponent = el)}
          {...this.props}
          emailInputOpened={emailInputOpened}
          borderColor={borderColor}
          onButtonClick={onClick}
          ctaText={ctaText}
          useCtaText={!!ctaText}
          placeholderFocusColor={borderColor}
        />
        <style jsx>{componentStyle}</style>
      </div>
    );
  }
}

ExpandingSignupComponent.defaultProps = {
  emailInputOpened: true,
  alignCenter: false,
  focusOnLoad: false,
  focusTimeout: 0,
  buttonColor: "brand-pink"
};

export default withErrorHandler(withGenericConfig(ExpandingSignupComponent));
