// Used for GTM

module.exports = {
  HUBSPOT_FORM_SUBMITTED: "hubspotFormSubmitted",
  IN_PAGE_AB_TEST_EVENT_NAME: "inPageAbTest",
  SHOW_COOKIEHUB_DIALOG_EVENT_NAME: "showCookiehubDialog",
  SIGNUP_CLICKED: "signupClicked",
  SIGNUP_WITH_GOOGLE_CLICKED: "signupWithGoogleClicked",
  TAG_SELECTED: "tagSelected"
};
