import { BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";

export const isKeyPressOnCustomControlClickEvent = (e) => {
  return e?.key === "Enter" || e?.key === " " || e?.key === "Spacebar" || e?.which === 13 || e?.which === 32;
};

export const generateOnKeyDownHandler = (onClick, ...parameters) => {
  return (event) => {
    if (isKeyPressOnCustomControlClickEvent(event)) {
      event.preventDefault();
      onClick(event, ...parameters);
    }
  };
};

// the 'mousedown' event also emits 'focus' by default,
// so if we want to prevent focusing a keyboard-navigable element on click, we just need to catch that event.
export const preventFocusOnMouseDown = (event) => event.preventDefault();

export const setSVGTitle = (containingSelector, title) => {
  const specialCharactersRegex = /[^\w\s]/gi;
  let cleanSelector = containingSelector.replace(specialCharactersRegex, "");
  // bug fix:
  if (containingSelector.startsWith("#") && !cleanSelector.startsWith("#")) {
    cleanSelector = `#${cleanSelector}`;
  }
  const element = document.querySelector(`${cleanSelector} svg > title`);
  if (element?.textContent) {
    element.textContent = title;
  }
};

const ACCESSIBLE_COLOR_TRANISTIONS = {
  pink: BRAND_BLACK_TEXT_COLOR
};

export const getAccessibleButtonColor = (color) => {
  return ACCESSIBLE_COLOR_TRANISTIONS[color] || color;
};
