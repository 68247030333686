import { PureComponent, Fragment } from "react";
import componentStyles from "./email-input-component.scss";

import { EMAIL_PATTERN } from "constants/input-validations-patterns";

export default class EmailInput extends PureComponent {
  constructor(props) {
    super(props);
    this._input = null;
    this.focus = this.focus.bind(this);
  }

  componentDidMount() {
    const { placeholderFocusColor } = this.props;
    if (placeholderFocusColor) {
      this._input.style.setProperty("--placholder-focus-color", placeholderFocusColor);
    }
  }

  componentDidUpdate() {
    const { focusOnUpdate } = this.props;
    focusOnUpdate && setTimeout(this.focus, 0);
  }

  focus() {
    this._input.focus();
  }

  isValid() {
    //Dont use input pattern, it doesnt work in safari
    return this._input.checkValidity() && EMAIL_PATTERN.test(this._input.value);
  }

  render() {
    const { focusOnUpdate, forceHorizontal, placeholderFocusColor, ...otherProps } = this.props;
    return (
      <Fragment>
        <input {...otherProps} ref={(el) => (this._input = el)} />
        <style jsx>{componentStyles}</style>
      </Fragment>
    );
  }
}

EmailInput.defaultProps = {
  value: "",
  autoComplete: "off",
  autoCapitalize: "off",
  spellCheck: "false",
  className: "email",
  type: "email",
  placeholder: "",
  name: "email",
  required: true,
  focusOnUpdate: false
};
