import css from "styled-jsx/css";
import colors from "/styles/colors";
import { TABLET_QUERY_MAX_WIDTH } from "/constants/vp-sizes";

const ONLY_BUTTON_WIDTH = "160px";
const FULL_WIDTH = "386px";
const MOBILE_WIDTH = "300px";
const HEIGHT = "48px";

export const TRANSITION_TIMING_MILISECONDS = 200;
const WIDTH_TRANSITION = `width ${TRANSITION_TIMING_MILISECONDS}ms ease`;
const PADDING_TRANSITION = `padding ${TRANSITION_TIMING_MILISECONDS}ms ease`;

export const componentStyle = css.global`
  .signup-form-core-outer-wrapper {
    width: ${FULL_WIDTH};

    .signup-form-core-component-wrapper {
      position: relative;
      width: ${ONLY_BUTTON_WIDTH};
      transition: ${WIDTH_TRANSITION};

      form {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .signup-button-wrapper {
        position: absolute;
        width: ${ONLY_BUTTON_WIDTH};
        z-index: 2;
        right: 0;

        .signup-button {
          width: 100%;
          height: ${HEIGHT};
          font-size: 0.875rem;
        }
      }

      .signup-input {
        min-width: auto;
        max-width: none;
        height: ${HEIGHT};
        width: 100%;

        z-index: 1;
        transition: ${PADDING_TRANSITION};

        font-size: 0.875rem;
        line-height: 8px;
        padding: 12px 24px;
        border: 1px solid;
        border-radius: 40px;
        outline: none;

        &:hover::placeholder,
        &:focus::placeholder {
          color: var(--placholder-focus-color);
        }
      }

      &.error input,
      &.error input:focus {
        border-color: ${colors["error"]} !important;
      }

      &.opened-input {
        width: ${FULL_WIDTH};
        .signup-input {
          padding-right: ${ONLY_BUTTON_WIDTH};
        }
      }
    }
    &.align-center {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .signup-form-core-outer-wrapper {
      width: ${MOBILE_WIDTH};

      .signup-form-core-component-wrapper {
        text-align: center;
        width: 100%;

        form {
          flex-direction: column;
        }

        .signup-input {
          padding: 16px 32px;
          text-align: center;
          margin: 0 0 16px 0;
        }

        .signup-button-wrapper {
          position: relative;
          width: 100%;
          .signup-button {
            padding: 16px 32px;
          }
        }
        &.opened-input {
          width: 100%;
          .signup-input {
            padding: 0;
          }
        }
      }
    }
  }
`;
