import { PureComponent } from "react";
import classnames from "classnames";
import { parseText } from "client/services/monday-markup-language/monday-markup-language-service";

export default class MondayMarkupLanguageComponent extends PureComponent {
  render() {
    const { tagName: CustomTag, text, className, style } = this.props;

    if (!text) return null;

    const parsedText = parseText(text);

    return (
      <CustomTag
        className={classnames("monday-markup-language-component", className)}
        dangerouslySetInnerHTML={{ __html: parsedText }}
        style={style}
      />
    );
  }
}

MondayMarkupLanguageComponent.defaultProps = {
  tagName: "div"
};
