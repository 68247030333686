import css from "styled-jsx/css";

export const fontsStyles = css.global`
  button.ladda-button {
    &.small {
      font-size: 0.8125rem;
      padding: 12px 9vw;
      font-weight: 300;
    }
    
    &.xs {
      font-size: 0.8125rem;
      padding: 8px 16px;
      font-weight: 300;
    }

    &.sm {
      font-size: 0.8125rem;
      padding: 16px 32px;
      font-weight: 300;
    }

    &.xl {
      font-size: 1rem;
      padding: 16px 120px;
      font-weight: 400;
    }
  }
`;
